import * as AuthenticateAPI from "../actions/ApiCalls";

const createPriceOption = async (payload) => {
  return await AuthenticateAPI.postResponse(`/firm-services/create`, payload);
};

const getPriceOptions = async (payload) => {
  return await AuthenticateAPI.getResponse(`/firm-services`, payload);
};

const updatePriceOption = async (priceOptionId, payload) => {
  return await AuthenticateAPI.updateCall(
    `/firm-services/update/${priceOptionId}`,
    payload
  );
};

const deletePriceOption = async (payload) => {
  return await AuthenticateAPI.deleteResponse(
    `/firm-services/delete/${payload}`,
    payload
  );
};

const createInvite = async (payload) => {
  return await AuthenticateAPI.postResponse(`/invite-client/create`, payload);
};

const getInvitations = async (payload) => {
  return await AuthenticateAPI.getResponse(`/invite-client`, payload);
};

const deleteInvite = async (payload) => {
  return await AuthenticateAPI.deleteResponse(
    `/invite-client/${payload}`,
    payload
  );
};

const getFirmPriceOptions = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/firm-services/firmServices/${payload}`,
    payload
  );
};

const PriceOptionsService = {
  createPriceOption,
  getPriceOptions,
  updatePriceOption,
  deletePriceOption,
  createInvite,
  getInvitations,
  getFirmPriceOptions,
  deleteInvite,
};

export default PriceOptionsService;
