import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const AdminDashboard = React.lazy(() =>
  import("./views/Admin/Dashboard/Dashboard")
);
const SetPassword = React.lazy(() => import("./views/setPassword/SetPassword"));
const Client = React.lazy(() => import("./views/clients/Clients"));
const Plans = React.lazy(() => import("./views/Admin/Plan/Plans"));
const OfficerList = React.lazy(() => import("./views/clients/OfficerList"));
const ParentDetail = React.lazy(() => import("./views/clients/ParentDetail"));
const AddAdminPlan = React.lazy(() => import("./views/Admin/Plan/AddPlan"));
// const UpdateAdminFirms = React.lazy(() => import("./views/Admin/updateFirm"));
const Services = React.lazy(() => import("./views/Admin/Service/list"));
const FirmServices = React.lazy(() =>
  import("./views/FirmServices/ServicesList")
);
const AddFirmService = React.lazy(() =>
  import("./views/FirmServices/AddFirmService")
);
const AddAdminPriceOptions = React.lazy(() =>
  import("./views/Admin/PriceOptions/AddPriceOption")
);
const AdminPriceOptions = React.lazy(() =>
  import("./views/Admin/PriceOptions/PriceOptionsList")
);
const FirmServicesCategory = React.lazy(() =>
  import("./views/FirmServiceCategory/FirmCategoryList")
);
const FirmAddServiceCategory = React.lazy(() =>
  import("./views/FirmServiceCategory/FirmAddCategory")
);
const AddServices = React.lazy(() => import("./views/Admin/AddServices"));
const ServiceCategories = React.lazy(() =>
  import("./views/Admin/ServiceCategory/categoryList")
);
const AddServiceCategory = React.lazy(() =>
  import("./views/Admin/ServiceCategory/addServiceCategory")
);
const PlanSubscriptions = React.lazy(() =>
  import("./views/Admin/Plan/PlanSubscriptions")
);
const AllSubscriptions = React.lazy(() =>
  import("./views/Admin/Subscriptions/Subscriptions")
);
const EditSubscription = React.lazy(() =>
  import("./views/Admin/Subscriptions/EditSubscriptions")
);
const PaymentHistoryList = React.lazy(() => import("./views/PaymentHistory/PaymentHistoryList"))
const AdminIntegrations = React.lazy(() =>
  import("./views/Admin/Integrations/Integrations")
);
const AddIntegrations = React.lazy(() =>
  import("./views/Admin/Integrations/IntegrationsForm")
);
// const viewIntegration = React.lazy(
//   () => "./views/Admin/Integrations/IntegrationView"
// );
const CoinList = React.lazy(() => import("./views/coins/listCoin"));
const AddCoin = React.lazy(() => import("./views/coins/addCoin"));
const AdminFirms = React.lazy(() => import("./views/Admin/adminFirms"));
const UpdateAdminFirms = React.lazy(() => import("./views/Admin/updateFirm"));
const Documents = React.lazy(() => import("./views/documents/documents"));
const Templates = React.lazy(() => import("./views/documents/template"));
const Digitalsignature = React.lazy(() =>
  import("./views/digitalsignature/digitalsignature")
);
const ListTransaction = React.lazy(() =>
  import("./views/Admin/Transactions/listTransaction")
);
const ViewInvoice = React.lazy(() =>
  import("./views/Admin/Transactions/viewInvoice")
);
const Integration = React.lazy(() => import("./views/integration/integration"));
const Subscription = React.lazy(() =>
  import("./views/subscription/subscription")
);
const Inbox = React.lazy(() => import("./views/inbox/inbox"));
const AddClient = React.lazy(() => import("./views/clients/addClients"));
const CompanyDocument = React.lazy(() =>
  import("./views/documents/companyDocument")
);
const ClientsDocuments = React.lazy(() =>
  import("./views/clients/ClientsDocuments")
);
const AddPlan = React.lazy(() => import("./views/plans/addPlan"));
const PlanList = React.lazy(() => import("./views/plans/listPlan"));
const Profile = React.lazy(() => import("./views/settings/profile"));
const Setting = React.lazy(() => import("./views/settings/setting"));
const Invite = React.lazy(() => import("./views/invite/invite"));
const SubscriptionPlan = React.lazy(() =>
  import("./views/settings/subscriptionPlan")
);
const DefaultTemplate = React.lazy(() =>
  import("./views/templates/defaultTemplate")
);
const EditProfile = React.lazy(() => import("./views/settings/EditProfile"));
const ProposalFormat = React.lazy(() =>
  import("./views/Admin/ProposalFormat/ProposalFormatList")
);
const AddProposalFormat = React.lazy(() =>
  import("./views/Admin/ProposalFormat/AddProposalFormat")
);

const FirmProposalFormat = React.lazy(() =>
  import("./views/ProposalFormat/ProposalFormatList")
);
const FirmAddProposalFormat = React.lazy(() =>
  import("./views/ProposalFormat/AddProposalFormat")
);
const PriceOptions = React.lazy(() =>
  import("./views/PriceOptions/PriceOptionsList")
);
const AddPriceOption = React.lazy(() =>
  import("./views/PriceOptions/AddPriceOption")
);
const InvitationsList = React.lazy(() =>
  import("./views/InvitationsList/InvitationsList")
);
const AddEmailFormat = React.lazy(() =>
  import("./views/EmailFormats/AddEmailFormat")
);
const EmailFormatsList = React.lazy(() =>
  import("./views/EmailFormats/EmailFormatsList")
);

const ClientDueOnList = React.lazy(() =>
  import("./views/dashboard/ClientsDueOn")
);

const Colors = React.lazy(() => import("./views/theme/colors/Colors"));
const Typography = React.lazy(() =>
  import("./views/theme/typography/Typography")
);

// Base
const Accordion = React.lazy(() => import("./views/base/accordion/Accordion"));
const Breadcrumbs = React.lazy(() =>
  import("./views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("./views/base/cards/Cards"));
const Carousels = React.lazy(() => import("./views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("./views/base/collapses/Collapses"));
const ListGroups = React.lazy(() =>
  import("./views/base/list-groups/ListGroups")
);
const Navs = React.lazy(() => import("./views/base/navs/Navs"));
const Paginations = React.lazy(() =>
  import("./views/base/paginations/Paginations")
);
const Placeholders = React.lazy(() =>
  import("./views/base/placeholders/Placeholders")
);
const Popovers = React.lazy(() => import("./views/base/popovers/Popovers"));
const Progress = React.lazy(() => import("./views/base/progress/Progress"));
const Spinners = React.lazy(() => import("./views/base/spinners/Spinners"));
const Tables = React.lazy(() => import("./views/base/tables/Tables"));
const Tooltips = React.lazy(() => import("./views/base/tooltips/Tooltips"));

// Buttons
const Buttons = React.lazy(() => import("./views/buttons/buttons/Buttons"));
const ButtonGroups = React.lazy(() =>
  import("./views/buttons/button-groups/ButtonGroups")
);
const Dropdowns = React.lazy(() =>
  import("./views/buttons/dropdowns/Dropdowns")
);

//Forms
const ChecksRadios = React.lazy(() =>
  import("./views/forms/checks-radios/ChecksRadios")
);
const FloatingLabels = React.lazy(() =>
  import("./views/forms/floating-labels/FloatingLabels")
);
const FormControl = React.lazy(() =>
  import("./views/forms/form-control/FormControl")
);
const InputGroup = React.lazy(() =>
  import("./views/forms/input-group/InputGroup")
);
const Layout = React.lazy(() => import("./views/forms/layout/Layout"));
const Range = React.lazy(() => import("./views/forms/range/Range"));
const Select = React.lazy(() => import("./views/forms/select/Select"));
const Validation = React.lazy(() =>
  import("./views/forms/validation/Validation")
);

const Charts = React.lazy(() => import("./views/charts/Charts"));

// Icons
const CoreUIIcons = React.lazy(() =>
  import("./views/icons/coreui-icons/CoreUIIcons")
);
const Flags = React.lazy(() => import("./views/icons/flags/Flags"));
const Brands = React.lazy(() => import("./views/icons/brands/Brands"));

// Notifications
const Alerts = React.lazy(() => import("./views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("./views/notifications/badges/Badges"));
const Modals = React.lazy(() => import("./views/notifications/modals/Modals"));
const Toasts = React.lazy(() => import("./views/notifications/toasts/Toasts"));

const Widgets = React.lazy(() => import("./views/widgets/Widgets"));

const adminRoutes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: AdminDashboard },
  { path: "/plans", name: "Plans", element: Plans },
  { path: "/plans/addAdminPlan", name: "AddAdminPlans", element: AddAdminPlan },
  { path: "/coins", name: "List Coins ", element: CoinList },
  { path: "/coins/add", name: "Add Coin", element: AddCoin },
  { path: "/adminFirms", name: "Firms", element: AdminFirms },
  {
    path: "/adminFirms/updateFirm",
    name: "Update Firms",
    element: UpdateAdminFirms,
  },
  {
    path: "/plans/planSubscriptions",
    name: "PlanSubscriptions",
    element: PlanSubscriptions,
  },
  {
    path: "/subscriptions",
    name: "AllSubscriptions",
    element: AllSubscriptions,
  },
  {
    path: "/subscriptions/editSubscription",
    name: "EditSubscription",
    element: EditSubscription,
  },
  {
    path: "/integrations",
    name: "Integrations",
    element: AdminIntegrations,
  },
  {
    path: "/integrations/addAdminIntegration",
    name: "AddAdminIntegration",
    element: AddIntegrations,
  },
  // {
  //   path: "/integrations/viewIntegration",
  //   name: "ViewAdminIntegration",
  //   element: viewIntegration,
  // },
  {
    path: "/servicesCategory",
    name: "ServicesCategory",
    element: ServiceCategories,
  },
  {
    path: "/servicesCategory/addServiceCategory",
    name: "AddServiceCategory",
    element: AddServiceCategory,
  },
  { path: "/services", name: "Services", element: Services },
  {
    path: "/services/addServices",
    name: " Add Services",
    element: AddServices,
  },
  {
    path: "/priceOptions",
    name: "Price Options",
    element: AdminPriceOptions,
  },
  {
    path: "/priceOptions/addPriceOption",
    name: "Add Price Option",
    element: AddAdminPriceOptions,
  },
  {
    path: "/transactions",
    name: "List Transactions ",
    element: ListTransaction,
  },
  { path: "/transaction-invoice", name: "Invoice", element: ViewInvoice },
  {
    path: "/proposalFormat",
    name: "Proposal Templates",
    element: ProposalFormat,
  },
  {
    path: "/proposalFormat/addFormat",
    name: "Add Proposal Templates",
    element: AddProposalFormat,
  },
];

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/set-password", name: "Set Password", element: SetPassword },
  { path: "/client", name: "Client", element: Client },
  { path: "/documents", name: "Documents", element: Templates },
  { path: "/documents/editor", name: "templates", element: Documents },
  {
    path: "/client/documents",
    name: "ClientDocuments",
    element: ClientsDocuments,
  },
  {
    path: "/client/officers",
    name: "ClientOfficers",
    element: OfficerList,
  },
  {
    path: "/client/parent",
    name: "ParentDetail",
    element: ParentDetail,
  },
  {
    path: "/companyDocument",
    name: "CompanyDocument",
    element: CompanyDocument,
  },
  { path: "/companyD", name: "ClientDocuments", element: ClientsDocuments },
  {
    path: "/digitalsignature",
    name: "Digitalsignature",
    element: Digitalsignature,
  },
  { path: "/integration", name: "integration", element: Integration },
  { path: "/subscription", name: "subscription", element: Subscription },
  { path: "/inbox", name: "inbox", element: Inbox },
  { path: "/invite", name: "invite", element: Invite },
  { path: "/profile", name: "profile", element: Profile },
  { path: "/setting", name: "setting", element: Setting },
  {
    path: "/subscriptionPlan",
    name: "subscriptionPlan",
    element: SubscriptionPlan,
  },
  { path: "/transaction-invoice", name: "Invoice", element: ViewInvoice },
  { path: "/client/add", name: "add client", element: AddClient },
  {
    path: "/client/documents",
    name: "client documents",
    element: ClientsDocuments,
  },
  {
    path: "/proposalFormat",
    name: "Proposal Templates",
    element: FirmProposalFormat,
  },
  {
    path: "/proposalFormat/addFormat",
    name: "Add Proposal Templates",
    element: FirmAddProposalFormat,
  },
  {
    path: "/firmServicesCategory",
    name: "FirmServicesCategory",
    element: FirmServicesCategory,
  },
  {
    path: "/firmServicesCategory/firmAddServiceCategory",
    name: "FirmAddServiceCategory",
    element: FirmAddServiceCategory,
  },
  {
    path: "/firmServices/firmAddServices",
    name: "Firm Add Services",
    element: AddFirmService,
  },
  { path: "/firmServices", name: "FirmServices", element: FirmServices },
  {
    path: "/priceOptions",
    name: "Price Options",
    element: PriceOptions,
  },
  {
    path: "/priceOptions/addPriceOption",
    name: "Add Price Option",
    element: AddPriceOption,
  },
  {
    path: "/invitationsList",
    name: "Invitations List",
    element: InvitationsList,
  },
  {
    path: "/emailFormats",
    name: "Email Formats",
    element: EmailFormatsList,
  },
  {
    path: "/emailFormats/addEmailFormat",
    name: "Add Email Format",
    element: AddEmailFormat,
  },
  {
    path: "/clientDueOn",
    name: "Due On Clients",
    element: ClientDueOnList,
  },
  { path: "/client/add", name: "Add client", element: AddClient },
  { path: "/plan/add", name: "Add plan", element: AddPlan },
  { path: "/plans", name: "List plan", element: PlanList },
  {
    path: "/paymentHistory",
    name: "Payment History",
    element: PaymentHistoryList,
  },
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/theme/typography", name: "Typography", element: Typography },
  { path: "/base", name: "Base", element: Cards, exact: true },
  { path: "/base/accordion", name: "Accordion", element: Accordion },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", element: Breadcrumbs },
  { path: "/base/cards", name: "Cards", element: Cards },
  { path: "/base/carousels", name: "Carousel", element: Carousels },
  { path: "/base/collapses", name: "Collapse", element: Collapses },
  { path: "/base/list-groups", name: "List Groups", element: ListGroups },
  { path: "/base/navs", name: "Navs", element: Navs },
  { path: "/base/paginations", name: "Paginations", element: Paginations },
  { path: "/base/placeholders", name: "Placeholders", element: Placeholders },
  { path: "/base/popovers", name: "Popovers", element: Popovers },
  { path: "/base/progress", name: "Progress", element: Progress },
  { path: "/base/spinners", name: "Spinners", element: Spinners },
  { path: "/base/tables", name: "Tables", element: Tables },
  { path: "/base/tooltips", name: "Tooltips", element: Tooltips },
  { path: "/buttons", name: "Buttons", element: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", element: Buttons },
  { path: "/buttons/dropdowns", name: "Dropdowns", element: Dropdowns },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    element: ButtonGroups,
  },
  { path: "/charts", name: "Charts", element: Charts },
  { path: "profile/editProfile", name: "EditProfile", element: EditProfile },
  { path: "/forms", name: "Forms", element: FormControl, exact: true },
  { path: "/forms/form-control", name: "Form Control", element: FormControl },
  { path: "/forms/select", name: "Select", element: Select },
  {
    path: "/forms/checks-radios",
    name: "Checks & Radios",
    element: ChecksRadios,
  },
  { path: "/forms/range", name: "Range", element: Range },
  { path: "/forms/input-group", name: "Input Group", element: InputGroup },
  {
    path: "/forms/floating-labels",
    name: "Floating Labels",
    element: FloatingLabels,
  },
  { path: "/forms/layout", name: "Layout", element: Layout },
  { path: "/forms/validation", name: "Validation", element: Validation },
  { path: "/icons", exact: true, name: "Icons", element: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", element: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", element: Flags },
  { path: "/icons/brands", name: "Brands", element: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    element: Alerts,
    exact: true,
  },
  { path: "/notifications/alerts", name: "Alerts", element: Alerts },
  { path: "/notifications/badges", name: "Badges", element: Badges },
  { path: "/notifications/modals", name: "Modals", element: Modals },
  { path: "/notifications/toasts", name: "Toasts", element: Toasts },
  { path: "/widgets", name: "Widgets", element: Widgets },
  // { path: '/defaultTemplate', name: 'DefaultTemplate', element: DefaultTemplate },
];

export { adminRoutes, routes };
