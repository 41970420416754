module.exports = `<html>
<head>
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="Generator" content="Microsoft Word 15 (filtered)" />
  <title>Example Letter of Engagement for Personal Tax Clients</title>
  <style>
	<!--
	 /* Font Definitions */
	 @font-face
		{font-family:Wingdings;
		panose-1:5 0 0 0 0 0 0 0 0 0;}
	@font-face
		{font-family:"Cambria Math";
		panose-1:2 4 5 3 5 4 6 3 2 4;}
	@font-face
		{font-family:Garamond;
		panose-1:2 2 4 4 3 3 1 1 8 3;}
	@font-face
		{font-family:"NewsGoth BT";}
	@font-face
		{font-family:Tahoma;
		panose-1:2 11 6 4 3 5 4 4 2 4;}
	 /* Style Definitions */
	 p.MsoNormal, li.MsoNormal, div.MsoNormal
		{margin-top:6.0pt;
		margin-right:0in;
		margin-bottom:6.0pt;
		margin-left:0in;
		font-size:12.0pt;
		font-family:"Garamond",serif;}
	p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
		{mso-style-link:"Body Text Char";
		margin-top:6.0pt;
		margin-right:0in;
		margin-bottom:6.0pt;
		margin-left:0in;
		text-align:justify;
		font-size:12.0pt;
		font-family:"Garamond",serif;}
	p.MsoBodyText2, li.MsoBodyText2, div.MsoBodyText2
		{margin-top:6.0pt;
		margin-right:0in;
		margin-bottom:6.0pt;
		margin-left:0in;
		line-height:200%;
		font-size:12.0pt;
		font-family:"Garamond",serif;}
	p.indent, li.indent, div.indent
		{mso-style-name:indent;
		margin-top:0in;
		margin-right:0in;
		margin-bottom:0in;
		margin-left:.5in;
		text-align:justify;
		text-indent:-.5in;
		punctuation-wrap:simple;
		text-autospace:none;
		font-size:11.0pt;
		font-family:"Times New Roman",serif;}
	p.Text, li.Text, div.Text
		{mso-style-name:Text;
		margin-top:0in;
		margin-right:0in;
		margin-bottom:13.0pt;
		margin-left:0in;
		text-align:justify;
		punctuation-wrap:simple;
		text-autospace:none;
		font-size:11.0pt;
		font-family:"Times New Roman",serif;}
	span.BodyTextChar
		{mso-style-name:"Body Text Char";
		mso-style-link:"Body Text";
		font-family:"Garamond",serif;}
	 /* Page Definitions */
	 @page WordSection1
		{size:595.35pt 842.0pt;
		margin:1.0in 89.85pt 1.0in 89.85pt;}
	div.WordSection1
		{page:WordSection1;}
	 /* List Definitions */
	 ol
		{margin-bottom:0in;}
	ul
		{margin-bottom:0in;}
	.content-list {
			padding-top: 30px;
		  }
  </style>
</head>

<body lang="EN-US" style="word-wrap: break-word">
  <div class="WordSection1">
	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>The directors of
		<span style="background: yellow" id="companyName">XYZ LTD</span></span
	  >
	</p>

	<p class="MsoBodyText">
	  <span
		lang="EN-GB"
		style="font-family: 'Arial', sans-serif; background: yellow"
		id="letterDate"
		>01 April 2019</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Dear <span style="background: yellow" id="companyValue">XYZ</span> ,</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We are pleased to accept the instruction to act as accountant for
		your company and are writing to confirm the terms of our
		appointment.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>The purpose of this letter, together with the attached terms and
		conditions, is to set out our terms for carrying out the work and to
		clarify our respective responsibilities.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We are bound by the ethical guidelines of ACCA, and accept
		instructions to act for you on the basis that we will act in
		accordance with those ethical guidelines. A copy of these guidelines
		can be viewed at our offices on request or at www.accaglobal.com</span
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Period of engagement</span
		></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>1.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>This letter is effective from
		<span style="background: yellow" id="startDate">12 March 2019
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>2.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We will deal with matters arising in respect of periods prior to the
		above date as appropriate.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Scope of services to be provided</span
		></b
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Our responsibility to you</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="margin-left: 22.7pt; text-align: justify; text-indent: -22.7pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>3.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We have set out the agreed scope and objectives of your instructions
		within this letter of engagement. Any subsequent changes will be
		discussed with you and where appropriate a new letter of engagement
		will be agreed. We shall proceed on the basis of the instructions we
		have received from you and will rely on you to tell us as soon as
		possible if anything occurs which renders any information previously
		given to us as incorrect or inaccurate. We shall not be responsible
		for any failure to advise or comment on any matter that falls outside
		the specific scope of your instructions. We cannot accept any
		responsibility for any event, loss or situation unless it is one
		against which it is the expressed purpose of these instructions to
		provide protection.</span
	  >
	</p>

	<p class="MsoNormal" style="text-align: justify">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Your responsibility to us</span
		></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>4.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>The advice that we give can only be as good as the information on
		which it is based. In so far as that information is provided by you,
		or by third parties with your permission, your responsibility arises
		as soon as possible if any circumstances or facts alter, as any
		alteration may have a significant impact on the advice given. If the
		circumstances change therefore or your needs alter, advise us of the
		alteration as soon as possible in writing.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Statutory responsibilities</span
		></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>5.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>As directors of the company, you are required by statute to prepare
		accounts (financial statements) for each financial year, which give a
		true and fair view of the state of affairs of the company and of its
		profit or loss for that period. In preparing those accounts you
		must:</span
	  >
	</p>

	<p
	  class="MsoBodyText"
	  style="margin-left: 51.05pt; text-indent: -28.35pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>5.1.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Select suitable accounting policies and then apply them
		consistently.</span
	  >
	</p>

	<p
	  class="MsoBodyText"
	  style="margin-left: 51.05pt; text-indent: -28.35pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>5.2.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Make judgments and estimates that are reasonable and prudent.</span
	  >
	</p>

	<p
	  class="MsoBodyText"
	  style="margin-left: 51.05pt; text-indent: -28.35pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>5.3.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Prepare the accounts on the going concern basis unless it is not
		appropriate to presume that the company will continue in
		business.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You have engaged us to prepare the accounts on your behalf.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>6.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>It is your responsibility to keep proper accounting records that
		disclose with reasonable accuracy at any particular time the financial
		position of the company. It is also your responsibility to safeguard
		the assets of the company and for taking reasonable steps for the
		prevention of and detection of fraud and other irregularities with an
		appropriate system of internal controls.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>7.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You are responsible for determining whether, in respect of the year
		concerned, the company meets the conditions for exemption from an
		audit set out in section 477 of the Companies Act 2006, and for
		determining whether, in respect of the year, the exemption is not
		available for any of the reasons set out in section 478 of the
		Companies Act 2006.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>8.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You are also responsible for making available to us, as and when
		required, all the company’s accounting records and all other relevant
		records and related information, including minutes of management and
		shareholders’ meetings.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You will also be responsible for:</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 58.7pt; text-indent: -0.5in">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.1.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Maintaining records of all receipts and payments of cash.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 58.7pt; text-indent: -0.5in">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.2.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Maintaining records of invoices issued and received.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 58.7pt; text-indent: -0.5in">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.3.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Reconciling balances annually</span
	  ><span lang="EN-GB"> </span
	  ><span class="MsoCommentReference"
		><span
		  lang="EN-GB"
		  style="font-size: 8pt; font-family: 'Arial', sans-serif"
		  >with</span
		></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif">
		the bank statements.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 58.7pt; text-indent: -0.5in">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.4.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Preparing details of the following at the year end of stocks and work
		in progress; fixed assets; amounts owing to suppliers; amounts owing
		by customers; and accruals and prepayments.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>10.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Our work will not be an audit of the accounts in accordance with
		International Standards on Auditing (UK and Ireland). Accordingly we
		shall not seek any independent evidence to support the entries in the
		accounting records, or to prove the existence, ownership or valuation
		of assets or completeness of income, liabilities or disclosure in the
		accounts. Nor shall we assess the reasonableness of any estimates or
		judgments made in the preparation of the accounts. Consequently our
		work will not provide any assurance that the accounting records are
		free from material misstatement, irregularities or error.
	  </span>
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>11.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>As part of our normal procedures we may request you to provide
		written confirmation of any oral information and explanations given to
		us during the course of our work.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>12.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We have a professional duty to compile accounts that conform with
		generally accepted accounting principles .The accounts of a limited
		company are required to comply with the disclosure requirements of the
		Companies Act 2006 and applicable accounting standards. Where we
		identify that the accounts do not conform to accepted accounting
		principles or standards, we will inform you and suggest amendments be
		put through the accounts before being published. We have a
		professional responsibility not to allow our name to be associated
		with accounts that may be misleading. In extreme cases, where this
		matter cannot be resolved, we will withdraw from the engagement and
		notify you in writing of the reasons.
	  </span>
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>13.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Should you instruct us to carry out any alternative report it will be
		necessary for us to issue a separate letter of engagement.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Our service to you</span
		></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>14.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We will not be carrying out any audit work as part of this assignment
		and accordingly will not verify the assets and liabilities of the
		company, nor the items of expenditure and income. To carry out an
		audit would entail additional work to comply with International
		Standards on Auditing so that we could report on the truth and
		fairness of the financial statements. We would also like to emphasise
		that we cannot undertake to discover any shortcomings in your systems
		or irregularities on the part of your employees.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>15.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>If an audit of the accounts is required, you will need to notify us
		in writing. Should our work indicate that the company is not entitled
		to exemption from an audit of the accounts, we will inform you. If we
		decide to undertake an audit assignment at your request, a separate
		engagement letter will be required.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>16.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>To ensure that anyone reading the accounts is aware that we have not
		carried out an audit, we will attach to the accounts a report stating
		this fact.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>17.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>The intended users of the report are the directors. The report will
		be addressed to the directors.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>18.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Once we have issued our report we have no further direct
		responsibility in relation to the accounts for that financial year.
		However, we expect that you will inform us of any material event
		occurring between the date of our report and that of the annual
		general meeting that may affect the accounts.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Limitation of liability</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="margin-left: 22.7pt; text-align: justify; text-indent: -22.7pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>19.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We specifically draw your attention to paragraphs 42 to 47 of our
		standard terms and conditions that set out the basis on which we limit
		our liability to you and to others. You should read this in
		conjunction with paragraphs 62 and 63 of our standard terms and
		conditions that exclude liability to third parties. </span
	  ><span
		lang="EN-GB"
		style="font-family: 'Arial', sans-serif; letter-spacing: -0.1pt"
		>These are important provisions which you should read and consider
		carefully.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="margin-left: 22.7pt; text-align: justify; text-indent: -22.7pt"
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>20.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>There are no third parties that we have agreed should be entitled to
		rely on the work done pursuant to this engagement letter.</span
	  >
	</p>

	<p class="MsoNormal" style="margin-left: 22.7pt; text-align: justify">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p
	  class="indent"
	  style="
		margin-top: 6pt;
		margin-right: 0in;
		margin-bottom: 6pt;
		margin-left: 0in;
		text-indent: 0in;
	  "
	>
	  <b
		><span
		  lang="EN-GB"
		  style="font-size: 12pt; font-family: 'Arial', sans-serif"
		  >Other services</span
		></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>21.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You may request that we provide other services from time to time. If
		these services will exceed £1500, we will issue a separate letter of
		engagement and scope of work to be performed accordingly.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>22.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Because rules and regulations frequently change you must ask us to
		confirm any advice already given if a transaction is delayed or a
		similar transaction is to be undertaken.</span
	  >
	</p>

	<p
	  class="indent"
	  style="
		margin-top: 6pt;
		margin-right: 0in;
		margin-bottom: 6pt;
		margin-left: 0in;
		text-indent: 0in;
	  "
	>
	  <b
		><span
		  lang="EN-GB"
		  style="font-size: 12pt; font-family: 'Arial', sans-serif"
		  >Agreement of terms         
		</span></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>23.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>This letter supersedes any previous engagement letter. Once it has
		been agreed, this letter will remain effective until it is
		replaced.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>24.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You or we may vary or terminate our authority to act on your behalf
		at any time without penalty. Notice of variation or termination must
		be given in writing.
	  </span>
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>25.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We would be grateful if you could confirm your agreement to the terms
		of this letter by signing the enclosed copy and returning it to us
		immediately.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>26.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>If this letter is not in accordance with your understanding of the
		scope of our engagement or your circumstances have changed, please let
		us know.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >27.<span style="font: 7pt 'Times New Roman'"
			>&nbsp;&nbsp;
		  </span></span
		></b
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>This letter should be read in conjunction with the firm’s standard
		terms and conditions.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  > <u>TAX</u></span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>1.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Thank you for engaging us as your tax practitioners.  I will be your
		main point of contact and will have primary responsibility for this
		assignment; the manager responsible for the ongoing work will be
		assigned to you .This letter and the attached schedules of services
		together with this firm’s standard terms and conditions set out the
		basis on which we will act.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Who we are acting for</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span class="MsoCommentReference"
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >2.<span style="font: 7pt 'Times New Roman'"
			>&nbsp;&nbsp;&nbsp;
		  </span></span
		></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We are acting for
		<span style="background: yellow" id="senderName">MEEMPRO LTD</span>  ( ‘you’)
		. Where you would like us to act for anyone else such as directors
		personal tax etc we will issue a separate engagement letter to them.
		We enclose an HMRC form 64-8 for you to sign and return to us for
		submission to HMRC.   This authorises HMRC to communicate with us as
		your agent, although they consider that you should still take
		“reasonable care” over your tax affairs.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>3.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span class="MsoCommentReference"
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >For</span
		></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif">
		the avoidance of doubt <span style="background: yellow">Mr </span>  is
		acting as nominated director. Any change to the nominated person
		should be notified to us in writing and will not be effective until
		acknowledged by us in writing. </span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>By signing this engagement letter you confirm and warrant that the
		Nominated Person set out above is authorised to give instructions and
		information to us on your behalf and to receive our advice and work
		product on behalf of you.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Period of engagement</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>4.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>This engagement will started on
		<span style="background: yellow" id="startDateVal"> 12 March 2019</span>. We will not
		deal with earlier years unless you specifically ask us to do so.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Scope of services</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>5.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Attached are the schedules of services listed below which records the
		work we are instructed to carry out.  These also states your and our
		responsibilities in relation to the work to be carried out. 
	  </span>
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>6.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>If we agree to carry out additional services for you, we will provide
		you with a new or amended engagement letter.  Only the services which
		are listed in the attached schedules are included within the scope of
		our instructions.  If there is additional work that you wish us to
		carry out which is not listed in the schedule(s), please let us know
		and we will discuss with you whether they can be included in the scope
		of our work.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Fees</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>7.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Our fees will be charged in accordance with our standard terms and
		conditions.  Please review these to ensure you understand the basis of
		our charges and our payment terms.</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Limitation of liability</span
		></b
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>8.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>We specifically draw your attention to paragraphs 42 to 47 of our
		standard terms and conditions which set out the basis on which we
		limit our liability to you and to others.  You should read this in
		conjunction with paragraphs 62 and 63 of our standard terms and
		conditions which exclude liability to third parties. </span
	  ><span
		lang="EN-GB"
		style="font-family: 'Arial', sans-serif; letter-spacing: -0.1pt"
		>These are important provisions which you should read and consider
		carefully.</span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif">  </span>
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0.25in;
		text-align: justify;
		text-indent: -0.25in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>9.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>There are no Third Parties that we have agreed should be entitled to
		rely on the work done pursuant to this engagement letter.    <br />
		<br />
		</span>
	</p>
	<div id="terms-content">

	</div>

	<p class="MsoBodyText">
	  <b
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >Other services
		</span></b
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>11.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>You may request that we provide other services from time to time. If
		these services will exceed £3000, we may will issue a separate letter
		of engagement and scope of work to be performed accordingly.</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt; text-indent: -22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>12.<span style="font: 7pt 'Times New Roman'"
		  >&nbsp;&nbsp;
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Because rules and regulations frequently change, you must ask us to
		confirm any advice already given if a transaction is delayed or a
		similar transaction is to be undertaken.</span
	  >
	</p>

	<p
	  class="Text"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
	  "
	>
	  <i
		><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		  >&nbsp;</span
		></i
	  >
	</p>

	<p
	  class="Text"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
	  "
	>
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Please confirm your agreement to the terms of this letter
		<span style="background: yellow">( pages 1 to 23),</span> the attached
		schedule of services and the standard terms and conditions by signing
		and returning one copy of this letter or E- approve via IRIS OpenSpace
		/ E-Signature. .</span
	  >
	</p>

	<p class="MsoBodyText" style="margin-left: 22.7pt">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>Yours sincerely,</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>For and on behalf of</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" id="senderValue" style="font-family: 'Arial', sans-serif"
		>MEEM ASSOCIATES – Chartered Certified Accountants</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif">I c</span
	  ><span class="MsoCommentReference"
		><span
		  lang="EN-GB"
		  style="
			font-size: 8pt;
			font-family: 'Arial', sans-serif;
			display: none;
		  "
		  > 
		</span></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>onfirm that I </span
	  ><span class="MsoCommentReference"
		><span
		  lang="EN-GB"
		  style="
			font-size: 8pt;
			font-family: 'Arial', sans-serif;
			display: none;
		  "
		  > </span
		></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>have read and understood the contents of this letter and related
		terms and conditions and agree that it accurately reflects my fair
		understanding of the services that I </span
	  ><span class="MsoCommentReference"
		><span
		  lang="EN-GB"
		  style="
			font-size: 8pt;
			font-family: 'Arial', sans-serif;
			display: none;
		  "
		  > </span
		></span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>require you to undertake.</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		>&nbsp;</span
	  >
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif"
		id="letterInitDate">
		Date...............           
	  </span>
	</p>

	<p class="MsoBodyText">
	  <span lang="EN-GB" style="font-family: 'Arial', sans-serif">
		 For and on behalf of</span
	  >
	</p>

	<p
	  class="MsoNormal"
	  style="
		margin-top: 0.1in;
		margin-right: 0in;
		margin-bottom: 0.1in;
		margin-left: 0in;
		text-align: justify;
	  "
	>
	  <span
		lang="EN-GB"
		id="companyDesignation"
		style="font-family: 'Arial', sans-serif; background: yellow"
		>XYZ LTD</span
	  ><span lang="EN-GB" style="font-family: 'Arial', sans-serif">
		- Director</span
	  >
	</p>

	<div class="content-list">
	  <div id="signVal" style="text-align: center"></div>
	</div>
  </div>
</body>
</html>

`;
