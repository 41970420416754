import React, { Component, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./scss/style.scss";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getObjectData } from "src/services/Storage";
import Dashboard from "./views/dashboard/Dashboard";
import DefaultTemplate from "./views/templates/defaultTemplate";
import LetterOfEngagement from "./views/templates/letterOfEngagement";
import SetClientPassword from "./views/setPassword/SetClientPassword";
import SettingsService from "./services/Settings";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ContextProvider } from "./context";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Reset = React.lazy(() => import("./views/pages/reset/reset"));
const Otp = React.lazy(() => import("./views/pages/OTP/otp"));
const ConfirmPassword = React.lazy(() =>
  import("./views/pages/confirmPassword/confirmPassword")
);
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  state = {
    isAuthenticated: true,
    Roles: [],
  };

  stripePromise = null;

  componentDidMount = async () => {
    let userDetail = await getObjectData("auth");
    let loggedIn = userDetail ? true : false;
    this.setState({ isAuthenticated: loggedIn });
    this.getStripeKey();
  };

  getStripeKey = async () => {
    try {
      const response = await SettingsService.getStripeKey();
      if (response.status === 200) {
        this.stripePromise = loadStripe(response.data);
      }
    } catch (error) {
      toast.error("Payment cannot be initiated!");
    }
  };

  render() {
    return (
      <Elements stripe={this.stripePromise}>
        <ContextProvider>
          <div>
            <ToastContainer autoClose={1500} />
            <BrowserRouter>
              <Suspense fallback={loading}>
                <Routes>
                  <Route
                    exact
                    path="/defaultTemplate/:firmProposalId/:clientProfileId/:firmProfileId/:letterId"
                    name="DefaultTemplate"
                    element={<DefaultTemplate />}
                  />
                  <Route
                    exact
                    path="/defaultTemplate/:firmProposalId/:clientProfileId/:firmProfileId"
                    name="DefaultTemplate"
                    element={<DefaultTemplate />}
                  />
                  <Route
                    exact
                    path="/letterOfEngagement/:firmProposalId/:clientProfileId/:firmProfileId"
                    name="LetterOfEngagement"
                    element={<LetterOfEngagement />}
                  />
                  <Route
                    exact
                    path="/setClientPassword/:userId"
                    name="SetClientPassword"
                    element={<SetClientPassword />}
                  />
                  {this.state.isAuthenticated ? (
                    <Route path="*" name="Home" element={<DefaultLayout />} />
                  ) : (
                    <>
                      <Route path="*" element={<Navigate to="/" replace />} />
                      <Route
                        exact
                        path="/"
                        name="Login Page"
                        element={<Login />}
                      />
                      <Route
                        exact
                        path="/register"
                        name="Register Page"
                        element={<Register />}
                      />
                      <Route
                        exact
                        path="/reset"
                        name="Reset Page"
                        element={<Reset />}
                      />
                      <Route
                        exact
                        path="/otp"
                        name="Otp Page"
                        element={<Otp />}
                      />
                      <Route
                        exact
                        path="/confirmPassword"
                        name="ConfirmPassword Page"
                        element={<ConfirmPassword />}
                      />
                      <Route
                        exact
                        path="/404"
                        name="Page 404"
                        element={<Page404 />}
                      />
                      <Route
                        exact
                        path="/500"
                        name="Page 500"
                        element={<Page500 />}
                      />
                    </>
                  )}
                </Routes>
              </Suspense>
            </BrowserRouter>
          </div>
        </ContextProvider>
      </Elements>
    );
  }
}

export default App;
