import * as AuthenticateAPI from "../actions/ApiCalls";

const getFirmClients = async (payload) => {
  return await AuthenticateAPI.getResponse(`/firm/clients`);
};

const addFirmClient = async (payload) => {
  return await AuthenticateAPI.postResponse(`/firm/clients/create`, payload);
};

const updateFirmClient = async (clientId, payload) => {
  return await AuthenticateAPI.updateCall(
    `/firm/clients/update/${clientId}`,
    payload
  );
};

const updateClientOfficer = async (officerId, payload) => {
  return await AuthenticateAPI.updateCall(
    `/firm/clients/officer-update/${officerId}`,
    payload
  );
};

const clientVerification = async (clientId) => {
  return await AuthenticateAPI.updateCall(
    `/firm/clients/save-company-details/${clientId}`,
    {}
  );
};

const getClientProfile = async (clientProfileId) => {
  return await AuthenticateAPI.getResponse(
    `/client/getClient/${clientProfileId}`,
    clientProfileId
  );
};

const deleteFirmClient = async (payload) => {
  return await AuthenticateAPI.deleteResponse(
    `/firm/clients/delete/${payload}`,
    payload
  );
};

const getClientsDocuments = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/templates/viewClientBasedTemplate/${payload}`,
    payload
  );
};

const addClientsDocument = async (payload) => {
  return await AuthenticateAPI.postResponse(
    `/templates/createReceiverTemplate`,
    payload
  );
};

const deleteClientsDocument = async (payload) => {
  return await AuthenticateAPI.deleteResponse(
    `/templates/receiverTemplate/${payload}`,
    payload
  );
};

const getCompanyHouseData = async (secretKey, companyNumber) => {
  return await AuthenticateAPI.getResponse(
    `/firm/clients/company-house-verification/${companyNumber}`,
    companyNumber
  );
};

const getCompanyHouseKey = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/firm/clients/company-house-key`,
    payload
  );
};

const getClientOfficers = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/firm/clients/company-officers/${payload}`,
    payload
  );
};

const getParentDetail = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/firm/clients/parent-detail/${payload}`,
    payload
  );
};

const addOfficerAsClient = async (payload) => {
  return await AuthenticateAPI.postResponse(
    `/firm/clients/create-official-client`,
    payload
  );
};

const sendDueNotification = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/client/period-due/mail/${payload}`,
    payload
  );
};

const sendConfirmNotification = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/client/confirmation-due/mail/${payload}`,
    payload
  );
};

const sendVatNotification = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/client/vat-due/mail/${payload}`,
    payload
  );
};

const getClientsDueOn = async (type) => {
  let url;
  switch(type){
    case 'annualDueCount':
      url = `/firm/clients/due-on-date`;
      break;
    case 'vatDueCount':
      url = `/firm/clients/vat-due-date`;
      break;
    case 'confirmationDueCount':
      url = `/firm/clients/confirmation-due-date`;
      break;
      default:
        url =  `/firm/clients/due-on-date`;
  }
  return await AuthenticateAPI.getResponse(
    url
  );
};

const ClientsServices = {
  getFirmClients,
  addFirmClient,
  updateFirmClient,
  updateClientOfficer,
  deleteFirmClient,
  getClientsDocuments,
  addClientsDocument,
  deleteClientsDocument,
  clientVerification,
  getClientProfile,
  getCompanyHouseData,
  getCompanyHouseKey,
  getClientOfficers,
  getParentDetail,
  addOfficerAsClient,
  sendDueNotification,
  sendConfirmNotification,
  sendVatNotification,
  getClientsDueOn
};

export default ClientsServices;
