import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormTextarea,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCheck, cilPencil, cilPlus, cilPrint } from "@coreui/icons";
import { cilMagnifyingGlass } from "@coreui/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import authService from "../../services/Auth.js";
import DocumentsServices from "../../services/Templates.js";
import ClientsServices from "../../services/Clients.js";
import ProfileService from "../../services/Profile.js";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import PriceOptionsService from "src/services/PriceOptions.js";
import "./style1.css";

var __html = require("./pdf.js");
var __html2 = require("./letterOfEngagementpdf.js");

const template = { __html: __html };
const letterTemplate = { __html: __html2 };

const DefaultTemplate = (props) => {
  const documentRef = useRef();
  const signRef = useRef();
  const fileInputRef = useRef();
  const paramData = useParams();
  const [firmData, setFirmData] = useState(null);
  const [proposalData, setProposalData] = useState(null);
  const [letterInfo, setLetterInfo] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [completeDocData, setCompleteData] = useState(null);
  const [signImage, setSignImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [priceOptions, setPriceOptions] = useState([]);
  const [selectedPriceOpt, setSelectedPriceOpt] = useState(null);
  const [amendmentText, setAmendmentText] = useState("");
  const [data, setData] = useState({
    startDate: moment().format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY"),
    senderEmail: "xyz@yopmail.com",
    receiverEmail: "abc@yopmail.com",
    senderName: "xyz",
    receiverName: "abc",
    senderPost: "firm",
    receiverPost: "client",
    companyName: "Sofyrus Technologies",
    logoUrl: null,
    colorScheme: null,
    proposalCode: "#MEEM1111",
    services: [],
    tax: 0,
    taxValue: 0,
    firmIntroduction: null,
  });
  const [letterData, setLetterData] = useState({
    letterDate: moment().format("DD-MM-YYYY"),
    letterInitDate: moment().format("DD-MM-YYYY"),
    companyName: "Sofyrus Technologies",
    companyValue: "Sofyrus Technologies",
    companyDesignation: "Sofyrus Technologies",
    startDate: moment().format("DD-MM-YYYY"),
    startDateVal: moment().format("DD-MM-YYYY"),
    senderName: "xyz",
    senderValue: "xyz",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [letter, setLetter] = useState(false);
  const [showLetter, setShowLetter] = useState(false);
  const [amendMentModal, setAmendmentModal] = useState(false);
  const [signModal, setSignModal] = useState(false);

  useEffect(() => {
    getFirmData(paramData.firmProfileId);
    if (paramData.letterId) setLetter(true);
    // setDocumentData();
  }, []);

  useEffect(() => {
    if (firmData && proposalData && clientData && !showLetter) {
      getFirmPriceOptions(firmData.user.id);
      const dataValue = { ...data };
      dataValue.companyName = proposalData.proposalContent.company;
      dataValue.startDate = proposalData.proposalContent.startDate;

      dataValue.endDate = proposalData.proposalContent.endDate;
      dataValue.senderEmail = firmData.user.email;
      dataValue.receiverEmail = clientData.user.email;
      dataValue.senderName = `${firmData.user.firstName} ${firmData.user.lastName}`;
      dataValue.receiverName = proposalData?.proposalContent?.officer
        ? `${proposalData?.proposalContent?.officer}`
        : `${clientData.user.firstName} ${clientData.user.lastName}`;
      dataValue.logoUrl = profileImage ? profileImage : null;
      dataValue.colorScheme =
        firmData && firmData.colorScheme ? firmData.colorScheme : null;
      dataValue.proposalCode = proposalData.proposalCode;
      dataValue.services = proposalData.proposalContent.services;
      dataValue.tax =
        proposalData && proposalData.proposalContent.tax
          ? proposalData.proposalContent.tax
          : 0.0;
      dataValue.taxValue =
        proposalData && proposalData.proposalContent.taxValue
          ? proposalData.proposalContent.taxValue
          : 0;
      dataValue.firmIntroduction =
        firmData && firmData.firmIntroduction
          ? firmData.firmIntroduction
          : null;
      if (
        proposalData &&
        proposalData.proposalContent &&
        proposalData.proposalContent.officerRole
      ) {
        dataValue.receiverPost = proposalData.proposalContent.officerRole;
      }
      setDocumentData(dataValue);
      setCompleteData(dataValue);
    } else if (firmData && letterInfo && clientData && showLetter) {
      const letterDataVal = { ...letterData };
      letterDataVal.companyDesignation = letterInfo.engagementContent.company;
      letterDataVal.letterDate = moment(letterInfo.created_at).format(
        "DD-MM-YYYY"
      );
      letterDataVal.letterInitDate = letterInfo.engagementContent.startDate;
      letterDataVal.companyName = letterInfo.engagementContent.company;
      letterDataVal.companyValue = letterInfo.engagementContent.company;
      letterDataVal.startDate = letterInfo.engagementContent.startDate;
      letterDataVal.startDateVal = letterInfo.engagementContent.startDate;
      letterDataVal.senderName = `${firmData.user.firstName} ${firmData.user.lastName}`;
      letterDataVal.senderValue = `${firmData.user.firstName} ${firmData.user.lastName}`;
      letterDataVal.servicesCategory =
        letterInfo.engagementContent.servicesCategory;
      setLetterOfEngagementData(letterDataVal);
    }
  }, [firmData, proposalData, clientData, showLetter]);

  const getFirmData = async (firmProfileId) => {
    try {
      const response = await authService.getFirmProfile(firmProfileId);
      if (response.status === 200) {
        const firmData = { ...response.data };
        setFirmData(firmData);
        if (firmData.logoUrl) {
          if (firmData.logoUrl.includes("http")) {
            setProfileImage(firmData.logoUrl);
          } else getProfileImage(firmData.logoUrl);
        }
        getProposalData(paramData.firmProposalId);
        if (paramData.letterId) getLetterData(paramData.letterId);
      } else {
        setError(false);
      }
    } catch (error) {
      setError(false);
    }
  };

  const getFirmPriceOptions = async (firmUserId) => {
    try {
      const response = await PriceOptionsService.getFirmPriceOptions(
        firmUserId
      );
      if (response.status === 200 && response.data.length) {
        const priceData = response.data.map((item) => {
          return { ...item, selected: false };
        });
        if (
          proposalData &&
          proposalData.proposalContent &&
          proposalData.proposalContent.priceOptions
        ) {
          const priceOpt = priceData.filter((item) =>
            proposalData.proposalContent.priceOptions.priceOptionsIds.includes(
              item.id
            )
          );
          setPriceOptions([...priceOpt]);
        } else setPriceOptions([]);
      } else {
        setPriceOptions([]);
      }
    } catch (error) {
      setPriceOptions([]);
    }
  };

  const selectOption = (item, index) => {
    const option = priceOptions.find((ele) => ele.id === item.id);
    setSelectedPriceOpt(option);
    const data = priceOptions.map((data) => {
      if (data.id === item.id) {
        return { ...data, selected: true };
      } else return { ...data, selected: false };
    });
    setPriceOptions([...data]);
  };

  const sendOptionResponse = async () => {
    try {
      const optionsIds = priceOptions.map((ele) => ele.id);
      const proposalInfo = { ...proposalData };
      const payload = {
        priceOptions: {
          priceOptionsIds: optionsIds,
        },
        acceptedServiceId: selectedPriceOpt.id,
        clientId: paramData.clientProfileId,
        userId: firmData.user.id,
        proposalId: proposalInfo.id,
        ...proposalInfo,
        letterOfEngagement: false,
      };
      const response = await PriceOptionsService.createInvite(payload);
      if (response.status === 201) {
        toast.success("Price Options Acquired!");
      } else {
        toast.error("Unable to acquire price option response!");
      }
    } catch (error) {
      toast.error("Unable to acquire price option response!");
    }
  };

  const getProfileImage = async (imageUrl) => {
    try {
      const response = await ProfileService.getProfileImage({ imageUrl });
      if (response.status === 201) {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setProfileImage("data:;base64," + base64);
      } else {
        setProfileImage(null);
      }
    } catch (error) {
      setProfileImage(null);
    }
  };

  const getProposalData = async (proposalId) => {
    try {
      const response = await DocumentsServices.getProposal(proposalId);
      if (response.data) {
        setProposalData(response.data);
        getClientProfile(paramData.clientProfileId);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const getLetterData = async (letterId) => {
    try {
      const response = await DocumentsServices.getLetterOfEngagement(letterId);
      if (response.status === 200) {
        setLetterInfo(response.data);
      } else {
        toast.error("Unable to acquire letter of engagement data!");
      }
    } catch (error) {
      toast.error("Unable to acquire letter of engagement data!");
    }
  };

  const getClientProfile = async (clientProfileId) => {
    try {
      const response = await ClientsServices.getClientProfile(clientProfileId);
      if (response.status === 200) {
        setClientData(response.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const setDocumentData = (data) => {
    let startDate = document.querySelector("#startDate");
    let endDate = document.querySelector("#endDate");
    let senderName = document.querySelector("#senderName");
    let receiverName = document.querySelector("#receiverName");
    let senderEmail = document.querySelector("#senderEmail");
    let receiverEmail = document.querySelector("#receiverEmail");
    let senderPost = document.querySelector("#senderPost");
    let receiverPost = document.querySelector("#receiverPost");
    let startDateBelow = document.querySelector("#startDateBelow");
    let companyName = document.querySelector("#companyName");
    let companyTitle = document.querySelector("#companyTitle");
    let proposalCode = document.querySelector("#proposalCode");

    startDate.innerHTML = data.startDate;
    endDate.innerHTML = data.endDate;
    senderName.innerHTML = data.senderName;
    receiverName.innerHTML = data.receiverName;
    senderEmail.innerHTML = data.senderEmail;
    receiverEmail.innerHTML = data.receiverEmail;
    senderPost.innerHTML = data.senderPost;
    receiverPost.innerHTML = data.receiverPost;
    startDateBelow.innerHTML = data.startDate;
    companyName.innerHTML = data.companyName;
    companyTitle.innerHTML = data.companyName;
    proposalCode.innerHTML = data.proposalCode;

    if (data && data.logoUrl) {
      document.querySelector(".img-style").src = data.logoUrl;
    }

    function idealTextColor(bgColor) {
      var nThreshold = 105;
      var components = getRGBComponents(bgColor);
      var bgDelta =
        components.R * 0.299 + components.G * 0.587 + components.B * 0.114;

      return 255 - bgDelta < nThreshold ? "#000000" : "#ffffff";
    }

    function getRGBComponents(color) {
      var r = color.substring(1, 3);
      var g = color.substring(3, 5);
      var b = color.substring(5, 7);

      return {
        R: parseInt(r, 16),
        G: parseInt(g, 16),
        B: parseInt(b, 16),
      };
    }

    if (data && data.services && data.services.length) {
      let totalPrice = 0;
      let tax = data.tax ? Number(data.tax).toFixed(2) : 0;
      let taxValue = data.taxValue ? data.taxValue : 0;
      let grossTotal = 0;
      const dataTable = document
        .querySelector(".services")
        .getElementsByTagName("tbody")[0];
      data.services.forEach((item) => {
        totalPrice =
          totalPrice + Number(Number(item.price) * Number(item.unit));
        let newRow = dataTable.insertRow();
        newRow.innerHTML = `<td class="custom-font">${item.title}</td>
          <td style="text-align: right;" class="custom-font">${item.price}</td>
          <td style="text-align: right;" class="custom-font">${item.unit}</td>
          <td style="text-align: right;" class="custom-font">${item.lineTotal}</td>
          `;
        const contentDiv = document.querySelector("#services-content");
        const info = document.createElement("div");
        info.innerHTML = `<div style="border-bottom:2px solid #424242;">
      <h2
        class="custom-font"
        style="color:#6fac8e;font-size:32px;font-family: sans-serif;font-weight: 500;margin-bottom:0px;text-transform: uppercase;">
        ${item.title}
      </h2>
    </div>
    <div>${item.description}</div>`;
        contentDiv.appendChild(info);
      });
      grossTotal = totalPrice + Number(Number(totalPrice * tax) / 100);
      let totalRow = dataTable.insertRow();
      totalRow.innerHTML = `<td style="text-align: right;
        background: #000;
        color: white;">Net Total</td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td id="totalCost" style="text-align: right;
        background: #000;
        color: white;">${totalPrice}</td>
        `;
      let vatRow = dataTable.insertRow();
      vatRow.innerHTML = `<td style="text-align: right;
        background: #000;
        color: white;">Tax Percent</td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td id="totalCost" style="text-align: right;
        background: #000;
        color: white;">${tax}</td>
        `;
      let taxValueRow = dataTable.insertRow();
      taxValueRow.innerHTML = `<td style="text-align: right;
      background: #000;
      color: white;">Tax Amount</td>
      <td style="text-align: right;
      background: #000;
      color: white;"></td>
      <td style="text-align: right;
      background: #000;
      color: white;"></td>
      <td id="totalCost" style="text-align: right;
      background: #000;
      color: white;">${taxValue}</td>
      `;
      let grossTotalRow = dataTable.insertRow();
      grossTotalRow.innerHTML = `<td style="text-align: right;
        background: #000;
        color: white;">Gross Total</td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td style="text-align: right;
        background: #000;
        color: white;"></td>
        <td id="totalCost" style="text-align: right;
        background: #000;
        color: white;">${grossTotal}</td>
        `;
    } else {
      document.querySelector("#serviceContent").innerHTML = "";
      document.querySelector("#serviceHeading").innerHTML = "";
      document.querySelector("#servicesDetail").innerHTML = "";
    }

    if (data && data.colorScheme) {
      let firstArray = document.querySelectorAll(".custom-color");
      firstArray.forEach((item) => {
        item.style.backgroundColor = data.colorScheme;
        const font = idealTextColor(data.colorScheme);
        item.style.color = font;
      });
      let secondArray = document.querySelectorAll(".custom-font");
      secondArray.forEach((item) => {
        item.style.color = data.colorScheme;
      });
    }
    if (data && data.firmIntroduction) {
      let intro = document.querySelector("#firm-intro");
      let introValue = intro.innerHTML;
      introValue =
        introValue +
        "<br><p>Brief Introduction of our team : </p><br>" +
        data.firmIntroduction;
      intro.innerHTML = introValue;
    }
  };

  const setLetterOfEngagementData = (data) => {
    let letterDate = document.querySelector("#letterDate");
    let letterInitDate = document.querySelector("#letterInitDate");
    let companyName = document.querySelector("#companyName");
    let companyValue = document.querySelector("#companyValue");
    let companyDesignation = document.querySelector("#companyDesignation");
    let startDate = document.querySelector("#startDate");
    let startDateVal = document.querySelector("#startDateVal");
    let senderName = document.querySelector("#senderName");
    let senderValue = document.querySelector("#senderValue");

    letterDate.innerHTML = data.letterDate;
    letterInitDate.innerHTML = data.letterDate;
    companyName.innerHTML = data.companyName;
    companyValue.innerHTML = data.companyName;
    companyDesignation.innerHTML = data.companyName;
    startDate.innerHTML = data.startDate;
    startDateVal.innerHTML = data.startDate;
    senderName.innerHTML = data.senderName;
    senderValue.innerHTML = data.senderName;

    // if (data && data.signature) {
    //   let signDiv = document.querySelector("#sign").childNodes;
    //   if (signDiv.length > 2) {
    //     signDiv[0].src = data.signature;
    //   } else {
    //     let img = document.createElement("img");
    //     let text = document.createElement("h2");
    //     img.src = data.signature;
    //     text.innerText = "Signature";
    //     document.querySelector("#sign").appendChild(img);
    //     document.querySelector("#sign").appendChild(text);
    //   }
    // }

    if (data && data.servicesCategory && data.servicesCategory.length) {
      data.servicesCategory.forEach((item) => {
        const contentDiv = document.querySelector("#terms-content");
        const info = document.createElement("div");
        info.innerHTML = `<div style="border-bottom:2px solid #424242;">
        <p><b>
          ${item.title}
        </b></p>
    </div>
    <div>${item.termConditions}</div>`;
        contentDiv.appendChild(info);
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => documentRef.current,
  });

  const requestAmendment = async () => {
    try {
      setLoading(true);
      let payload = new FormData();
      payload.append("proposalId", paramData.firmProposalId);
      payload.append("status", "amendment");
      payload.append("amendmentNote", amendmentText);
      payload.append("signBase", "");
      const response = await DocumentsServices.requestAmendment(payload);
      if (response.status === 201) {
        setLoading(false);
        setAmendmentText("");
        setAmendmentModal(false);
        toast.success("Amendment Submitted!");
      } else {
        setLoading(false);
        toast.error("Request Failed!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Request Failed!");
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const emailPdf = async (html, proposalId) => {
    try {
      const response = await DocumentsServices.emailPdf({
        pdfHtml: html,
        proposalId,
        clientData: clientData,
      });
      if (response.status === 201) {
        console.log("success");
      } else {
      }
    } catch (error) {}
  };

  const signDocument = async () => {
    try {
      setLoader(true);
      let payload = new FormData();
      payload.append("proposalId", paramData.firmProposalId);
      const getSignBase = signRef?.current?.toDataURL("image/png");
      payload.append("status", "accepted");
      if (signImage) {
        payload.append("signature", signImage);
        payload.append("signBase", "");
      } else {
        payload.append("signBase", getSignBase);
      }
      payload.append("amendmentNote", "xyz");
      const response = await DocumentsServices.signDocument(payload);
      if (response.status === 201) {
        setLoader(false);
        // signRef.current.clear();
        toast.success("Document Signed.");
        let signDiv = document.querySelector("#sign").childNodes;
        let signDivRef = document.querySelector("#sign");
        signDivRef.style.display = "flex";
        signDivRef.style.flexDirection = "column";
        signDivRef.style.alignItems = "center";
        if (signDiv.length >= 2) {
          signDiv[0].src = signImage ? await toBase64(signImage) : getSignBase;
        } else {
          let img = document.createElement("img");
          let text = document.createElement("h2");
          img.src = signImage ? await toBase64(signImage) : getSignBase;
          img.style.height = "180px";
          img.style.width = "auto";
          text.innerText = "Signature";
          document.querySelector("#sign").appendChild(img);
          document.querySelector("#sign").appendChild(text);
        }
        setSignModal(false);
        const pdfHtml = document.querySelector("#proposal").innerHTML;
        emailPdf(pdfHtml, paramData.firmProposalId);
      } else {
        setLoader(false);
        toast.error("Unable to sign document!");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Unable to sign document!");
    }
  };

  const signLetterEngagement = async () => {
    try {
      let payload = new FormData();
      payload.append("letterEngagementId", paramData.letterId);
      payload.append("status", "accepted");
      if (signImage) {
        payload.append("signature", signImage);
        payload.append("signBase", "");
      } else {
        payload.append("signBase", signRef.current.toDataURL("image/png"));
      }
      payload.append("amendmentNote", "xyz");
      const response = await DocumentsServices.signLetterEngagement(payload);
      if (response.status === 201) {
        toast.success("Letter Of Engagement Signed.");
      } else {
        toast.error("Unable to sign letter of engagement!");
      }
    } catch (error) {
      toast.error("Unable to sign letter of engagement!");
    }
  };

  const amendMentModalComponent = () => (
    <>
      <CModal visible={amendMentModal} onClose={() => setAmendmentModal(false)}>
        <CModalHeader
          closeButton={false}
          onClose={() => setAmendmentModal(false)}
        >
          <CModalTitle>Create Amendment Request : </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormTextarea
              id="exampleFormControlTextarea1"
              label="Write Amendment Details"
              rows="3"
              text="Write Complete Detail..."
              value={amendmentText}
              onChange={(e) => {
                setAmendmentText(e.target.value);
              }}
            ></CFormTextarea>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setAmendmentModal(false)}>
            Close
          </CButton>
          <CButton color="primary" onClick={() => requestAmendment()}>
            Send Amendment
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );

  const signatureComponent = () => (
    <>
      <CModal visible={signModal} onClose={() => setSignModal(false)}>
        <CModalHeader closeButton={false} onClose={() => setSignModal(false)}>
          <CModalTitle>Create Signature : </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <SignatureCanvas
            ref={signRef}
            onBegin={(e) => {
              fileInputRef.current.value = null;
              setSignImage(null);
            }}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              style: { border: 2, borderColor: "black" },
              className: "sigCanvas",
            }}
          />
          <CForm>
            <CFormInput
              ref={fileInputRef}
              type="file"
              id="signFile"
              label="Upload signature Image"
              onChange={(e) => {
                if (e.target.files.length) {
                  setSignImage(e.target.files[0]);
                  signRef.current.clear();
                }
              }}
            />
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setSignModal(false)}>
            Close
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              signDocument();
              if (selectedPriceOpt && priceOptions.length) sendOptionResponse();
              if (letter) signLetterEngagement();
            }}
          >
            Submit
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );

  const optionsComponent = () => (
    <div className="container-sm">
      <div className="d-flex justify-content-between flex-wrap">
        {priceOptions.length
          ? priceOptions.map((item, index) => (
              <div
                className="card-content"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  position: "relative",
                }}
              >
                <div className="card-desc" style={{ textAlign: "center" }}>
                  <h3>{item.title}</h3>
                  {item.selected && (
                    <CIcon icon={cilCheck} size="3xl" className="icon-set" />
                  )}

                  <div style={{ textAlign: "center" }}>
                    <h4>{item.subTitle}</h4>

                    <hr />
                    <p>Total Days : {item.days ? item.days : 0}</p>
                    <p className="abc">{item.amount} $</p>
                    {item.services &&
                    item.services.servicesData &&
                    item.services.servicesData.length
                      ? item.services.servicesData.map((ele) => (
                          <p>{ele.title}</p>
                        ))
                      : null}
                    <div style={{ padding: "30px 15px" }}>
                      <button
                        onClick={() => {
                          selectOption(item, index);
                        }}
                        className="btn-card"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );

  return (
    <div className=" align-items-center" style={{ backgroundColor: "white" }}>
      {letter && showLetter ? (
        <CContainer ref={documentRef} style={{ backgroundColor: "white" }}>
          <div
            id="letterEngagement"
            dangerouslySetInnerHTML={letterTemplate}
          ></div>
        </CContainer>
      ) : (
        <CContainer ref={documentRef} style={{ backgroundColor: "white" }}>
          <div id="proposal" dangerouslySetInnerHTML={template}></div>
        </CContainer>
      )}
      {optionsComponent()}
      <div
        className="d-flex justify-content-between"
        style={{ padding: 16, marginTop: 30 }}
      >
        <button className="btn  btn-primary sub-btn" onClick={handlePrint}>
          <CIcon icon={cilPrint} />
        </button>

        <button
          className="btn "
          style={{
            backgroundColor: "#6fac8e",
            height: 60,
            border: "none",
            borderRadius: 8,
            color: "#000",
            fontWeight: "500",
            width: "20%",
            cursor: "pointer",
            fontSize: 20,
          }}
          onClick={() => {
            if (!selectedPriceOpt && priceOptions.length) {
              alert("Please select a price option!");
              return;
            }
            if (proposalData && proposalData.status === "accepted") {
              toast.error("This proposal is already signed and accepted!");
              return;
            }
            setSignModal(true);
            setShowLetter(false);
          }}
        >
          Attach Signature
        </button>
        {letter && (
          <button
            className="btn "
            style={{
              backgroundColor: "yellow",
              height: 60,
              border: "none",
              borderRadius: 8,
              color: "#000",
              fontWeight: "500",
              width: "20%",
              cursor: "pointer",
              fontSize: 20,
            }}
            onClick={() => {
              setShowLetter(!showLetter);
            }}
          >
            {showLetter ? `View Proposal` : `View Letter Of Engagement`}
          </button>
        )}
        <CTooltip content="Add Amendment">
          <button
            className="btn  btn-primary sub-btn"
            onClick={() => {
              setAmendmentModal(true);
            }}
          >
            <CIcon icon={cilPencil} />
          </button>
        </CTooltip>
      </div>
      {amendMentModalComponent()}
      {signatureComponent()}
    </div>
  );
};

export default DefaultTemplate;
