import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
  cilExitToApp,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import avatar8 from "./../../assets/images/avatars/8.jpg";
import { clear, getObjectData } from "src/services/Storage";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import jwt from "jwt-decode";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const tokenData = await getObjectData("auth");
      const userData = jwt(tokenData.accessToken);
      if (userData) {
        setUser(userData);
      }
    } catch (error) {
      setUser(null);
    }
  };

  const logoutAlert = (e) => {
    e.preventDefault();
    try {
      swal({
        icon: "warning",
        title: "Are you sure?",
        text: "Log Out from this session",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: 'LogOut',
        cancelButtonText: "Cancel",
        buttons: true,
      }).then(async (value) => {
        if (value) {
          logoutUser();
        } else return;
      });
    } catch (error) {
      toast.error("Some Error Occured!");
    }
  };

  const logoutUser = async () => {
    await clear();
    window.location = "/";
  };
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <div
          style={{
            border: "3px solid",
            borderRadius: 25,
            borderColor: "black",
            width: 39,
            height: 39,
          }}
        >
          <CIcon icon={cilUser} size="xxl" style={{ padding: 2 }} />
        </div>
      </CDropdownToggle>
      {user && user.roles.includes("admin") ? (
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownHeader
            style={{ cursor: "pointer" }}
            className="bg-light fw-semibold py-2"
          >
            Settings
          </CDropdownHeader>
          <CDropdownItem style={{ cursor: "pointer" }} onClick={logoutAlert}>
            <CIcon icon={cilExitToApp} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      ) : (
        <CDropdownMenu className="pt-0" placement="bottom-end">
          {/* <CDropdownHeader className="bg-light fw-semibold py-2">Account</CDropdownHeader> */}
          {/* <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Updates
          <CBadge color="info" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
          {/* <CDropdownItem href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          Messages
          <CBadge color="success" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
          {/* <CDropdownItem href="#">
          <CIcon icon={cilTask} className="me-2" />
          Tasks
          <CBadge color="danger" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
          {/* <CDropdownItem href="#">
          <CIcon icon={cilCommentSquare} className="me-2" />
          Comments
          <CBadge color="warning" className="ms-2">
            42
          </CBadge>
        </CDropdownItem> */}
          <CDropdownHeader className="bg-light fw-semibold py-2">
            Settings
          </CDropdownHeader>
          <CDropdownItem href="/profile">
            <CIcon icon={cilUser} className="me-2" />
            Profile
          </CDropdownItem>
          {/* <CDropdownItem href="/setting">
            <CIcon icon={cilSettings} className="me-2" />
            Settings
          </CDropdownItem>
          <CDropdownItem href="/subscriptionPlan">
            <CIcon icon={cilCreditCard} className="me-2" />
            My Subscription
            <CBadge color="secondary" className="ms-2">
              42
            </CBadge>
          </CDropdownItem> */}

          <CDropdownDivider />

          <CDropdownItem onClick={logoutAlert} style={{ cursor: "pointer" }}>
            <CIcon icon={cilExitToApp} className="me-2" />
            Logout
          </CDropdownItem>
        </CDropdownMenu>
      )}
    </CDropdown>
  );
};

export default AppHeaderDropdown;
