import * as AuthenticateAPI from "../actions/ApiCalls";

const updateFirmProfile = async (payload) => {
  return await AuthenticateAPI.updateFormData(`/auth/firm-profile`, payload);
};

const getProfileImage = async (payload) => {
  return await AuthenticateAPI.getImageResponse(
    `/auth/firm-profileImage`,
    payload
  );
};

const updateLink = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/update-firm-logo`, payload);
};

const updateFirmColor = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/update-firm-color`, payload);
};

const ProfileService = {
  updateFirmProfile,
  getProfileImage,
  updateLink,
  updateFirmColor
};

export default ProfileService;
