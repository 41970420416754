import React, { createContext, useState } from "react";

export const ClientContext = createContext();
export const UserContext = createContext();
export const TemplateContext = createContext();
export const SubscriptionContext = createContext();

export const ContextProvider = (props) => {
  const [clientListData, setClientListData] = useState(null);
  const [user, setUser] = useState(null);
  const [templateListData, setTemplateListData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);

  return (
    <TemplateContext.Provider value={[templateListData, setTemplateListData]}>
      <ClientContext.Provider value={[clientListData, setClientListData]}>
        <SubscriptionContext.Provider
          value={[subscriptionData, setSubscriptionData]}
        >
          <UserContext.Provider value={[user, setUser]}>
            {props.children}
          </UserContext.Provider>
        </SubscriptionContext.Provider>
      </ClientContext.Provider>
    </TemplateContext.Provider>
  );
};
