import axios from "axios";
import { toast } from "react-toastify";
import Config from "../config";
import { getData, getObjectData, clear } from "../services/Storage";

export const postResponse = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "POST",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (url != "/auth/login" && error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response;
    });
};

export const getResponse = async (url, params) => {
  const URL = Config.BaseUrl + url;

  const header = await getHeader();
  return axios(URL, {
    method: "GET",
    headers: header,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response.data;
    });
};

export const getImageResponse = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios
    .post(URL, payload, { responseType: "arraybuffer", headers: header })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        // window.location = "/";
      }
      return error.response.data.errors;
    });
};

export const getResponseWithoutAuth = async (url, params) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "GET",
    headers: header,
  })
    .then((response) => response)
    .catch(async (error) => {
      return error;
    });
};

export const getCompanyHouseData = async (secretKey, companyNumber) => {
  // const URL = `https://api.companieshouse.gov.uk/company/${companyNumber}`;
  const header = {
    Accept: "application/json",
    Authorization: `Basic ${secretKey}`,
    "Accept-Encoding": "gzip",
  };
  return axios
    .get(`https://api.companieshouse.gov.uk/company/${companyNumber}`, {
      headers: header,
    })
    .then((response) => response)
    .catch(async (error) => {
      return error;
    });
};

export const updateCall = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "PUT",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response.data.errors ?? error.response.data.message;
    });
};

export const updateFormData = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader(true);
  return axios(URL, {
    method: "PUT",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response.data.errors ?? error.response.data.message;
    });
};

export const deleteResponse = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "DELETE",
    headers: header,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response.data.errors;
    });
};

export const postResponseFormData = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader(true);
  return axios
    .post(URL, payload, {
      headers: header,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status == 401) {
        await clear();
        toast.info("Session Expired! Please Log In!");
        window.location = "/";
      }
      return error.response.data.errors;
    });
};

//For Otp Only

export const postOtpResponse = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getOtpHeader();

  // console.log(header);
  return axios(URL, {
    method: "POST",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch((error) => {
      console.log(error);
    });
};

const getHeader = async (formData) => {
  let token = null;
  let tokenData = await getObjectData("auth");
  if (tokenData) token = `Bearer ${tokenData.accessToken}`;
  // token = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNmMDQ3ZjhkLTY0NGUtNDU4MC1iODVmLTQ3MWZmNWQ1ZmRiOSIsImZpcnN0TmFtZSI6Ik11c2hhcnJhZiIsImxhc3ROYW1lIjoiQWxpIEtoYW4iLCJlbWFpbCI6Inh5ekB5b3BtYWlsLmNvbSIsInJvbGVzIjpbImZpcm0iXSwiaWF0IjoxNjU5MzU1MTk3LCJleHAiOjE2NTkzNTg3OTd9.U6y1XgCb2Wp3DAmgDcfhgeHcV9vivkCoMEIjd4Si2OY`
  return {
    Accept: formData ? "application/json" : "application/json",
    "Content-Type": formData ? "multipart/form-data" : "application/json",
    Authorization: token,
  };
};

const getOtpHeader = async (formData) => {
  return {
    Accept: formData ? "application/json" : "application/json",
    "Content-Type": formData ? "multipart/form-data" : "application/json",
  };
};
