import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer, CSpinner } from "@coreui/react";
import { getObjectData } from "src/services/Storage";
import jwt from "jwt-decode";

// routes config
import { routes, adminRoutes } from "../routes";

const AppContent = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const tokenData = await getObjectData("auth");
      const userData = jwt(tokenData.accessToken);
      if (userData) {
        setUser(userData);
      }
    } catch (error) {
      setUser(null);
    }
  };

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <CContainer lg style={{ backgroundColor: "#fff" }}>
      <Suspense fallback={loading}>
        <Routes>
          {user && user.roles.includes("admin")
            ? adminRoutes.map((route, idx) => {
                return (
                  route.element && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                );
              })
            : routes.map((route, idx) => {
                return (
                  route.element && (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      element={<route.element />}
                    />
                  )
                );
              })}
          <Route path="/" element={<Navigate to="dashboard" replace />} />
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppContent);
