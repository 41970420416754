module.exports = `<html>
<head>
  <style>
    html {
      -webkit-print-color-adjust: exact;
    }
    #customers {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 15px;
      page-break-inside: avoid;
    }

    /* .custom-color {
    background-color: #e76e1e !important;
  }

  .custom-font {
    color: #e76e1e !important;
  } */

    #customers td,
    #customers th {
      border: 3px solid #fff;
      padding: 8px;
      background: #f8f8f8;
    }

    #customers th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: #6fac8e;
      color: black;
      font-weight: 500;
      text-transform: uppercase;
    }

    #customers td {
      color: #ff0000;
    }

    .img-style {
      width: 60%;
      margin: 0rem auto 10rem;
      display: block;
      padding-top: 15rem;
    }

    .toc-list,
    .toc-list ol {
      list-style-type: none;
    }

    .toc-list {
      padding: 0;
    }

    .toc-list ol {
      padding-inline-start: 2ch;
    }

    .toc-list li {
      padding: 6px 0;
    }

    .toc-list li > a {
      text-decoration: none;
      display: grid;
      grid-template-columns: auto max-content;
      align-items: end;
    }

    .toc-list li > a > .page {
      text-align: right;
      color: #000;
    }

    .visually-hidden {
      clip: rect(0 0 0 0);
      clip-path: inset(100%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      width: 1px;
      white-space: nowrap;
    }

    .toc-list li > a > .title {
      position: relative;
      overflow: hidden;
      color: #000;
      text-transform: uppercase;
      font-family: sans-serif;
    }

    .toc-list li > a .leaders::after {
      position: absolute;
      padding-inline-start: 0.25ch;
      content: " . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . "
        ". . . . . . . . . . . . . . . . . . . . . . . ";
      text-align: right;
    }

    .content-list {
      padding-top: 30px;
    }

    .app-btn {
      text-align: center;
      margin-top: 45px;
    }

    .app-btn button {
      background-color: #6fac8e;
      height: 60px;
      border: none;
      border-radius: 8px;
      color: #000;
      font-weight: 500;
      width: 40%;
      cursor: pointer;
    }
  </style>
</head>

<body>
  <!-- <div style="display: flex;
  justify-content: space-between;">
      <div>

       <h4>Name: Waseem Ahmad</h4>
       <h4>Age: 29 years</h4>
       <h4>Sex: Male</h4>
      </div>
      <div>
          <h4>Doctor Name: Javed Ahmad</h4>
          <h4>MRN No: DMW12345</h4>
          <h4>Date: 10/10/2022</h4>
      </div>
  </div> -->
  <div style="margin-bottom: 60px">
    <div>
      <img src="" alt="Loading Logo Image..." class="img-style" />
    </div>
    <div>
      <div style="text-align: center">
        <h2
          class="custom-font"
          style="
            color: #6fac8e;
            font-size: 40px;
            font-family: sans-serif;
            font-weight: 500;
            margin-bottom: 0px;
          "
        >
          Proposal for
        </h2>
        <h1
          id="companyTitle"
          style="
            color: #ff0000;
            font-size: 48px;
            font-weight: 500;
            font-family: sans-serif;
            margin-bottom: 0;
            margin-top: 10px;
          "
          class="custom-font"
        >
          IT WISECONSULTING LIMITED
        </h1>
        <h3
          style="
            color: #424242;
            font-size: 24px;
            font-family: sans-serif;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 3rem;
          "
        >
          Proposal
          <span
            style="
              color: #ff0000;
              font-size: 24px;
              font-family: sans-serif;
              font-weight: 500;
            "
            id="proposalCode"
            class="custom-font"
            >#MEEM1319</span
          >
        </h3>
      </div>

      <table id="customers">
        <tr>
          <th class="custom-color">Created By</th>
          <th class="custom-color">Created For</th>
        </tr>
        <tr>
          <td id="senderName" class="custom-font">Munaf Maniar</td>
          <td id="receiverName" class="custom-font">PAULO, Moreira</td>
        </tr>
        <tr>
          <td id="senderPost" class="custom-font">Partner</td>
          <td id="receiverPost" class="custom-font">Director</td>
        </tr>
        <tr>
          <td id="senderEmail" class="custom-font">
            munaf@meemassociates.com
          </td>
          <td id="receiverEmail" class="custom-font">7647187@gmail.com</td>
        </tr>
        <tr>
          <td class="custom-font">
            <span style="color: #424242" class="custom-font">t.</span>
            02079934683
          </td>
          <td></td>
        </tr>
        <tr>
          <td class="custom-font">
            <span style="color: #424242" class="custom-font">m.</span>
            02079934683
          </td>
          <td></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="content-list">
    <div style="border-bottom: 2px solid #424242">
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 32px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
        "
      >
        Contents
      </h2>
    </div>
    <ol class="toc-list" role="list">
      <li>
        <a href="#introduction">
          <span class="title"
            >Introduction<span class="leaders" aria-hidden="true"></span
          ></span>
          <span data-href="#introduction" class="page"
            ><span class="visually-hidden">Page&nbsp;</span>3</span
          >
        </a>
      </li>

      <li>
        <a href="#investment-costs">
          <span class="title"
            >INVESTMENT COSTS<span class="leaders" aria-hidden="true"></span
          ></span>
          <span data-href="#investment-costs" class="page"
            ><span class="visually-hidden">Page&nbsp;</span>4</span
          >
        </a>
      </li>

      <li>
        <a href="#What-happen-next?">
          <span class="title"
            >WHAT HAPPENS NEXT?<span class="leaders" aria-hidden="true"></span
          ></span>
          <span data-href="#what-happen-next?" class="page"
            ><span class="visually-hidden">Page&nbsp;</span>5</span
          >
        </a>
      </li>

      <li>
        <a href="#services-you-have-selected">
          <span class="title"
            >Services you have selected<span
              class="leaders"
              aria-hidden="true"
            ></span
          ></span>
          <span data-href="#services-you-have-selected" class="page"
            ><span class="visually-hidden">Page&nbsp;</span>7</span
          >
        </a>
      </li>
      <li>
        <a href="#service-on-your-roadmap">
          <span class="title"
            >Service on your roadmap<span
              class="leaders"
              aria-hidden="true"
            ></span
          ></span>
          <span data-href="#service-on-your-roadmap" class="page"
            ><span class="visually-hidden">Page&nbsp;</span>10</span
          >
        </a>
      </li>
    </ol>
  </div>
  <div class="content-list">
    <div style="border-bottom: 2px solid #424242">
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 32px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
        "
      >
        Introduction
      </h2>
    </div>
    <div>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
        id="firm-intro"
      >
        Welcome to your proposal.<br /><br />
        We are excited to be presenting it to you, as we know that it marks
        the start of exciting and positive times ahead for you and
        <span
          id="companyName"
          style="
            color: #ff0000;
            font-size: 16px;
            font-family: sans-serif;
            font-weight: 500;
          "
          class="custom-font"
          >IT WISECONSULTING LIMITED.</span
        >
        <br /><br />
        This proposal outlines the Accounting Services we discussed and which
        we feel will help drive your business to your next level of success…..
        and beyond
      </p>
    </div>
  </div>
  <div class="content-list">
    <div style="border-bottom: 2px solid #424242" id="serviceHeading">
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 32px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
        "
      >
        INVESTMENT COSTS
      </h2>
    </div>
    <div id="servicesDetail">
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        This section outlines the services, included in this proposal and the
        fees associated with those services. For a full breakdown of what each
        of these services includes, please check out the OUR SERVICES
        EXPLAINED section of this Proposal.
      </p>
      <table id="customers" class="services">
        <thead>
          <tr>
            <th class="custom-color">Services</th>
            <th class="custom-color">Fees $</th>
            <th class="custom-color">Unit</th>
            <th class="custom-color">Total</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 24px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
        "
      >
        Please Note
      </h2>

      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        This proposal and these fees are based on the following being
        accurate:
      </p>
      <table id="customers">
        <tr>
          <td
            class="custom-color"
            style="text-align: right; background: #6fac8e; color: #000"
          >
            Start Date
          </td>
          <td id="startDate" class="custom-font">April 2022</td>
        </tr>
        <tr>
          <td
            class="custom-color"
            style="text-align: right; background: #6fac8e; color: #000"
          >
            End Date
          </td>
          <td id="endDate" class="custom-font">March 2023</td>
        </tr>
      </table>
      <p
        style="
          color: #424242;
          font-size: 18px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        * This proposal is valid for 30 days
      </p>
    </div>
  </div>
  <div class="content-list">
    <div style="border-bottom: 2px solid #424242">
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 32px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
        "
      >
        WHAT HAPPENS NEXT?
      </h2>
    </div>
    <div>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        Moving over to us and getting the ball rolling couldn’t be simpler:
      </p>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        STEP 1 – Click to approve this Proposal and digitally sign your
        Engagement Letter
      </p>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        STEP 2 – We’ll then send over a link to set up your monthly payments
        via GoCardless
      </p>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        STEP 3 – We’ll get in touch with your last accountant and sort
        EVERYTHING! And don’t worry, they’ll still speak to you
      </p>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        STEP 4 – We will install our accounting system, which comprises of…
      </p>

      <ul>
        <li>
          <p
            style="
              color: #424242;
              font-size: 16px;
              font-family: sans-serif;
              font-weight: 500;
              margin-bottom: 0;
            "
          >
            Software – Xero set up & configured to track the performance of
            your agency
          </p>
        </li>
        <li>
          <p
            style="
              color: #424242;
              font-size: 16px;
              font-family: sans-serif;
              font-weight: 500;
              margin-top: 5px;
            "
          >
            Bookkeeping – Receipt Bank set up and integrated with your Xero
            account to increase the efficiency of your day-to-day financial
            admin
          </p>
        </li>
      </ul>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        STEP 5 – We will then provide your Onboarding Session for us to define
        the goals of the business and provide training to you and any members
        of your team that you’d like to bring in with you.
      </p>
      <div class="app-btn">
        <button class="custom-color">
          <span style="font-size: 20px">APPROVE MY PROPOSAL</span><br />
          <span>#MEEM1319</span>
        </button>
        <p
          style="
            color: #424242;
            font-size: 16px;
            font-family: sans-serif;
            font-weight: 500;
          "
        >
          This proposal is valid for 30 days from
          <span
            id="startDateBelow"
            style="color: #ff0000"
            class="custom-font"
          >
            6 July 2022.</span
          >
        </p>
      </div>
    </div>
  </div>
  <div class="content-list" id="serviceContent">
    <div style="border-bottom: 2px solid #424242">
      <h2
        class="custom-font"
        style="
          color: #6fac8e;
          font-size: 32px;
          font-family: sans-serif;
          font-weight: 500;
          margin-bottom: 0px;
          text-transform: uppercase;
        "
      >
        SERVICES YOU HAVE SELECTED
      </h2>
    </div>
    <div>
      <p
        style="
          color: #424242;
          font-size: 16px;
          font-family: sans-serif;
          font-weight: 500;
        "
      >
        Here is a full explanation of the services you have chosen in this
        Proposal.
      </p>
    </div>
  </div>
  <div class="content-list" id="services-content"></div>
  <div class="content-list">
    <div id="sign" style="text-align: center"></div>
  </div>
</body>
</html>

`
