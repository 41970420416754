import React, { useEffect, useState } from "react";
import loginlogo from "../../assets/images/meem.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { clear } from "src/services/Storage";
import { MutatingDots } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authService from "src/services/Auth";
import swal from "sweetalert";

const SetClientPassword = () => {
  const [value, setValue] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const paramData = useParams();

  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let payload = {
        password: value.password,
        userId: paramData.userId,
      };

      const response = await authService.setClientPassword(payload);
      if (response.status === 201) {
        // toast("Password added successfully!", {
        //   position: "top-right",
        //   autoClose: 1000,
        // });
        setLoading(false);
        swal({
          icon: "info",
          title: "Password Update Successful",
          type: "info",
          text: "You can use this password for login in mobile application with this email",
          // showCancelButton: false,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Ok",
          // buttons: true,
        }).then(async (value) => {
          if (value) {
            swal.close();
            await clear();
            window.location = "/";
          } else {
            swal.close();
            await clear();
            window.location = "/";
          }
        });
      } else {
        toast.error("Unable to set password!");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong.");
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <div className=" eq" style={{ height: "100vh", width: "100%" }}>
        <div className="login-card info col"></div>

        <div className="login-card col">
          <ToastContainer />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <form
              className="login-form"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img src={loginlogo} style={{ marginBottom: "40px" }} />
              <div className="input-floating-label">
                <input
                  className="input"
                  type="password"
                  id="input"
                  name="password"
                  placeholder="username"
                  onChange={onChange}
                />
                <label>New Password</label>
                <span className="focus-bg"></span>
              </div>
              <div className="input-floating-label">
                <input
                  className="input"
                  type="password"
                  id="input"
                  name="confirmPassword"
                  placeholder="password"
                  onChange={onChange}
                />
                <label>Confirm Password</label>
                <span className="focus-bg"></span>
              </div>

              {isLoading ? (
                <MutatingDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#00699f"
                  ariaLabel="three-dots-loading"
                  wrapperStyle
                  wrapperClass
                />
              ) : (
                <button
                  id="submit"
                  className="btn-submit"
                  onClick={(e) => handleClick(e)}
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetClientPassword;
