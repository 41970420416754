import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CCol,
  CContainer,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CModal,
  CModalBody,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CForm,
  CFormTextarea,
  CTooltip,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilPencil, cilPlus, cilPrint } from "@coreui/icons";
import { cilMagnifyingGlass } from "@coreui/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import authService from "../../services/Auth.js";
import DocumentsServices from "../../services/Templates.js";
import ClientsServices from "../../services/Clients.js";
import ProfileService from "../../services/Profile.js";
import { useReactToPrint } from "react-to-print";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";

var __html = require("./letterOfEngagementpdf.js");

const template = { __html: __html };

const LetterOfEngagement = (props) => {
  const documentRef = useRef();
  const signRef = useRef();
  const fileInputRef = useRef();
  const paramData = useParams();
  const [firmData, setFirmData] = useState(null);
  const [letterData, setLetterData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [signImage, setSignImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [amendmentText, setAmendmentText] = useState("");
  const [data, setData] = useState({
    startDate: moment().format("DD-MM-YYYY"),
    endDate: moment().format("DD-MM-YYYY"),
    senderEmail: "xyz@yopmail.com",
    receiverEmail: "abc@yopmail.com",
    senderName: "xyz",
    receiverName: "abc",
    senderPost: "firm",
    receiverPost: "client",
    companyName: "Sofyrus Technologies",
    logoUrl: null,
    colorScheme: null,
    proposalCode: "#MEEM1111",
    services: [],
    tax: 0,
    taxValue: 0,
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [amendMentModal, setAmendmentModal] = useState(false);
  const [signModal, setSignModal] = useState(false);

  useEffect(() => {
    getFirmData(paramData.firmProfileId);
    // setDocumentData();
  }, []);

  useEffect(() => {
    if (firmData && letterData && clientData) {
      const letterDataVal = { ...letterData };
      letterDataVal.companyDesignation = letterData.engagementContent.company;
      letterDataVal.letterDate = moment(letterData.created_at).format(
        "DD-MM-YYYY"
      );
      letterDataVal.letterInitDate = letterData.engagementContent.startDate;
      letterDataVal.companyName = letterData.engagementContent.company;
      letterDataVal.companyValue = letterData.engagementContent.company;
      letterDataVal.startDate = letterData.engagementContent.startDate;
      letterDataVal.startDateVal = letterData.engagementContent.startDate;
      letterDataVal.senderName = `${firmData.user.firstName} ${firmData.user.lastName}`;
      letterDataVal.senderValue = `${firmData.user.firstName} ${firmData.user.lastName}`;
      letterDataVal.services = letterData.engagementContent.services;
      setDocumentData(letterDataVal);
    }
  }, [firmData, letterData, clientData]);

  const getFirmData = async (firmProfileId) => {
    try {
      const response = await authService.getFirmProfile(firmProfileId);
      if (response.status === 200) {
        const firmData = { ...response.data };
        setFirmData(firmData);
        if (firmData.logoUrl) {
          if (firmData.logoUrl.includes("http")) {
            setProfileImage(firmData.logoUrl);
          } else getProfileImage(firmData.logoUrl);
        }
        getLetterData(paramData.firmProposalId);
      } else {
        setError(false);
      }
    } catch (error) {
      setError(false);
    }
  };

  const getProfileImage = async (imageUrl) => {
    try {
      const response = await ProfileService.getProfileImage({ imageUrl });
      if (response.status === 201) {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setProfileImage("data:;base64," + base64);
      } else {
        setProfileImage(null);
      }
    } catch (error) {
      setProfileImage(null);
    }
  };

  const getLetterData = async (letterId) => {
    try {
      const response = await DocumentsServices.getLetterOfEngagement(letterId);
      if (response.data) {
        setLetterData(response.data);
        getClientProfile(paramData.clientProfileId);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const getClientProfile = async (clientProfileId) => {
    try {
      const response = await ClientsServices.getClientProfile(clientProfileId);
      if (response.status === 200) {
        setClientData(response.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const setDocumentData = (data) => {
    let letterDate = document.querySelector("#letterDate");
    let letterInitDate = document.querySelector("#letterInitDate");
    let companyName = document.querySelector("#companyName");
    let companyValue = document.querySelector("#companyValue");
    let companyDesignation = document.querySelector("#companyDesignation");
    let startDate = document.querySelector("#startDate");
    let startDateVal = document.querySelector("#startDateVal");
    let senderName = document.querySelector("#senderName");
    let senderValue = document.querySelector("#senderValue");

    letterDate.innerHTML = data.letterDate;
    letterInitDate.innerHTML = data.letterDate;
    companyName.innerHTML = data.companyName;
    companyValue.innerHTML = data.companyName;
    companyDesignation.innerHTML = data.companyName;
    startDate.innerHTML = data.startDate;
    startDateVal.innerHTML = data.startDate;
    senderName.innerHTML = data.senderName;
    senderValue.innerHTML = data.senderName;

    // if (data && data.signature) {
    //   let signDiv = document.querySelector("#sign").childNodes;
    //   if (signDiv.length > 2) {
    //     signDiv[0].src = data.signature;
    //   } else {
    //     let img = document.createElement("img");
    //     let text = document.createElement("h2");
    //     img.src = data.signature;
    //     text.innerText = "Signature";
    //     document.querySelector("#sign").appendChild(img);
    //     document.querySelector("#sign").appendChild(text);
    //   }
    // }

    if (data && data.services && data.services.length) {
      data.services.forEach((item) => {
        const contentDiv = document.querySelector("#terms-content");
        const info = document.createElement("div");
        info.innerHTML = `<div style="border-bottom:2px solid #424242;">
        <p><b>
          ${item.title}
        </b></p>
    </div>
    <div>${item.termConditions}</div>`;
        contentDiv.appendChild(info);
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => documentRef.current,
  });

  const requestAmendment = async () => {
    try {
      setLoading(true);
      let payload = new FormData();
      payload.append("letterEngagementId", paramData.firmProposalId);
      payload.append("status", "amendment");
      payload.append("amendmentNote", amendmentText);
      payload.append("signBase", "");
      const response = await DocumentsServices.engagementAmendment(payload);
      if (response.status === 201) {
        setLoading(false);
        setAmendmentText("");
        setAmendmentModal(false);
        toast.success("Amendment Submitted!");
      } else {
        setLoading(false);
        toast.error("Request Failed!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Request Failed!");
    }
  };

  const signDocument = async () => {
    try {
      setLoader(true);
      let payload = new FormData();
      payload.append("letterEngagementId", paramData.firmProposalId);
      payload.append("status", "accepted");
      if (signImage) {
        payload.append("signature", signImage);
      } else {
        payload.append("signBase", signRef.current.toDataURL("image/png"));
      }
      payload.append("amendmentNote", "xyz");
      const response = await DocumentsServices.signLetterEngagement(payload);
      if (response.status === 201) {
        setLoader(false);
        setSignModal(false);
        // signRef.current.clear();
        toast.success("Document Signed.");
      } else {
        setLoader(false);
        toast.error("Unable to sign document!");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Unable to sign document!");
    }
  };

  const amendMentModalComponent = () => (
    <>
      <CModal visible={amendMentModal} onClose={() => setAmendmentModal(false)}>
        <CModalHeader
          closeButton={false}
          onClose={() => setAmendmentModal(false)}
        >
          <CModalTitle>Create Amendment Request : </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <CFormTextarea
              id="exampleFormControlTextarea1"
              label="Write Amendment Details"
              rows="3"
              text="Write Complete Detail..."
              value={amendmentText}
              onChange={(e) => {
                setAmendmentText(e.target.value);
              }}
            ></CFormTextarea>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setAmendmentModal(false)}>
            Close
          </CButton>
          <CButton color="primary" onClick={() => requestAmendment()}>
            Send Amendment
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );

  const signatureComponent = () => (
    <>
      <CModal visible={signModal} onClose={() => setSignModal(false)}>
        <CModalHeader closeButton={false} onClose={() => setSignModal(false)}>
          <CModalTitle>Create Signature : </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <SignatureCanvas
            ref={signRef}
            onBegin={(e) => {
              fileInputRef.current.value = null;
              setSignImage(null);
            }}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 200,
              style: { border: 2, borderColor: "black" },
              className: "sigCanvas",
            }}
          />
          <CForm>
            <CFormInput
              ref={fileInputRef}
              type="file"
              id="signFile"
              label="Upload signature Image"
              onChange={(e) => {
                if (e.target.files.length) {
                  setSignImage(e.target.files[0]);
                  signRef.current.clear();
                }
              }}
            />
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setSignModal(false)}>
            Close
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              signDocument();
            }}
          >
            Submit
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );

  return (
    <div className=" align-items-center" style={{ backgroundColor: "white" }}>
      <CContainer ref={documentRef} style={{ backgroundColor: "white" }}>
        <div dangerouslySetInnerHTML={template}></div>
      </CContainer>
      <div className="d-flex justify-content-between" style={{ padding: 16 }}>
        <button className="btn  btn-primary sub-btn" onClick={handlePrint}>
          <CIcon icon={cilPrint} />
        </button>

        <button
          className="btn "
          style={{
            backgroundColor: "#6fac8e",
            height: 60,
            border: "none",
            borderRadius: 8,
            color: "#000",
            fontWeight: "500",
            width: "40%",
            cursor: "pointer",
            fontSize: 20,
          }}
          onClick={() => {
            setSignModal(true);
          }}
        >
          Attach Signature
        </button>
        <CTooltip content="Add Amendment">
          <button
            className="btn  btn-primary sub-btn"
            onClick={() => {
              setAmendmentModal(true);
            }}
          >
            <CIcon icon={cilPencil} />
          </button>
        </CTooltip>
      </div>

      {amendMentModalComponent()}
      {signatureComponent()}
    </div>
  );
};

export default LetterOfEngagement;
