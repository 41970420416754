import * as AuthenticateAPI from "../actions/ApiCalls";

const login = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/login`, payload);
};

const registerFirm = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/register-firm`, payload);
};

const forgotPassword = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/forgotPassword`, payload);
};

const verifyPasswordOTP = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/verifyPasswordOTP`, payload);
};

const resetPassword = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/resetPassword`, payload);
};

const setNewPassword = async (payload) => {
  return await AuthenticateAPI.postResponse(`/auth/setNewPassword`, payload);
};

const getFirmProfile = async (firmProfileId) => {
  return await AuthenticateAPI.getResponse(
    `/auth/firm-profile/${firmProfileId}`,
    firmProfileId
  );
};

const getFirmProfileUser = async (userId) => {
  return await AuthenticateAPI.getResponse(`/auth/firm/${userId}`, userId)
}

const getFirmProfileSubscription = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/firm/clients/firmSettingsData`,
    payload
  );
};

const setClientPassword = async (payload) => {
  return await AuthenticateAPI.postResponse(
    `/firm/clients/set-password`,
    payload
  );
};

const authService = {
  login,
  registerFirm,
  forgotPassword,
  resetPassword,
  verifyPasswordOTP,
  setNewPassword,
  getFirmProfile,
  getFirmProfileUser,
  getFirmProfileSubscription,
  setClientPassword
};

export default authService;
