import React from "react";
import CIcon from "@coreui/icons-react";
import { cilEnvelopeClosed } from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { ReactComponent as DashboardIcon } from "./icons/Dashboard.svg";
import { ReactComponent as Clients } from "./icons/Clients.svg";
import { ReactComponent as Integration } from "./icons/Integration.svg";
import { ReactComponent as Invite } from "./icons/Invite.svg";
import { ReactComponent as Notification } from "./icons/Notification.svg";
import { ReactComponent as Proposals } from "./icons/Proposals.svg";
import { ReactComponent as Subscription } from "./icons/Subscription.svg";
import { ReactComponent as Plan } from "./icons/Plans.svg";
import { ReactComponent as Service } from "./icons/Services.svg";
import { ReactComponent as Transaction } from "./icons/Transaction.svg";
import { ReactComponent as Document } from "./icons/Documents.svg";
import { ReactComponent as Firm } from "./icons/Firms.svg";
import { ReactComponent as Coin } from "./icons/Coin.svg";
import { ReactComponent as Setting } from "./icons/Settings.svg";
// import DashboardIcon from "./icons/Dashboard.svg";
import { SvgIcon } from "@material-ui/core";

const _adminNav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <SvgIcon component={DashboardIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Firms",
    to: "/adminFirms",
    icon: <SvgIcon component={Firm} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Proposal Templates",
    to: "/proposalFormat",
    icon: <SvgIcon component={Document} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Services Category",
    to: "/servicesCategory",
    icon: <SvgIcon component={Service} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Services",
    to: "/services",
    icon: <SvgIcon component={Service} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Price Options",
    to: "/priceOptions",
    icon: <SvgIcon component={Notification} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Plans",
    to: "/plans",
    icon: <SvgIcon component={Plan} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Subscriptions",
    to: "/subscriptions",
    icon: <SvgIcon component={Subscription} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Integrations",
    to: "/integrations",
    icon: <SvgIcon component={Integration} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "MeemCoin",
    to: "/coins",
    icon: <SvgIcon component={Coin} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Transactions",
    to: "/transactions",
    icon: <SvgIcon component={Transaction} className="nav-icon" />,
  },
];

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <SvgIcon component={DashboardIcon} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Clients",
    to: "/client",
    icon: <SvgIcon component={Clients} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Proposal Templates",
    to: "/proposalFormat",
    icon: <SvgIcon component={Proposals} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Services Category",
    to: "/firmServicesCategory",
    icon: <SvgIcon component={Service} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Services",
    to: "/firmServices",
    icon: <SvgIcon component={Service} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Price Options",
    to: "/priceOptions",
    icon: <SvgIcon component={Notification} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Invitations",
    to: "/invitationsList",
    icon: <SvgIcon component={Invite} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Integration",
    to: "/integration",
    icon: <SvgIcon component={Integration} className="nav-icon" />,
  },

  {
    component: CNavItem,
    name: "Subscription",
    to: "/subscription",
    icon: <SvgIcon component={Subscription} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Email Formats",
    to: "/emailFormats",
    icon: <SvgIcon component={Plan} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Payment History",
    to: "/paymentHistory",
    icon: <SvgIcon component={Subscription} className="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Settings",
    to: "/setting",
    icon: <SvgIcon component={Setting} className="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: "Notification",
  //   to: "/inbox",
  //   icon: <SvgIcon component={Notification} className="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: "Invite",
  //   to: "/invite",
  //   icon: <SvgIcon component={Invite} className="nav-icon" />,
  // },
];

export { _nav, _adminNav };
