import * as AuthenticateAPI from "../actions/ApiCalls";

const getFirmDashBoardCount = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/dashboard/firm-dashboard-counts`,
    payload
  );
};

const getFirmDashboardProposals = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/dashboard/firm-dashboard-proposals`,
    payload
  );
};

const getAdminDashboardCounts = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/dashboard/admin-dashboard-counts`,
    payload
  );
};

const getAdminDashboardFirms = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/dashboard/admin-firm-data`,
    payload
  );
};

const getAdminDashboardSubscriptions = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/dashboard/admin-subscription-data`,
    payload
  );
};

const DashboardService = {
  getFirmDashBoardCount,
  getFirmDashboardProposals,
  getAdminDashboardCounts,
  getAdminDashboardFirms,
  getAdminDashboardSubscriptions,
};

export default DashboardService;
