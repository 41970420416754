import { React, useState, useRef, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
  CFormSwitch,
  CFormCheck,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilParagraph,
  cilPlus,
  cilUser,
  cilNotes,
  cilDescription,
  cilTrash,
  cilCheck,
  cilUserPlus,
} from "@coreui/icons";
import {
  CModalFooter,
  CModal,
  CModalTitle,
  CModalHeader,
  CModalBody,
  CButton,
  CRow,
  CCol,
  CDatePicker,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { InfinitySpin, Bars } from "react-loader-spinner";
import jwt from "jwt-decode";

import { AppBreadcrumb } from "./index";
import { AppHeaderDropdown } from "./header/index";
import logo from "../assets/images/meem.png";
import defaultImg from "../assets/image/testTemplate.png";
import SelectSearch from "react-select-search";
import DocumentsServices from "../services/Templates";
import ClientsServices from "../services/Clients";
import authService from "../services/Auth";
import { toast } from "react-toastify";
import { getObjectData } from "../services/Storage";
import ProfileService from "../services/Profile";
import {
  ClientContext,
  SubscriptionContext,
  TemplateContext,
} from "../context";
import RichTextEditor from "react-rte";
import { BsCheckCircleFill, BsCheckCircle } from "react-icons/bs";
import notFound from "../assets/image/page-not-found.png";
import swal from "sweetalert";

const AppHeader = () => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const [templateList, setTemplateList] = useState([]);
  const [templateListInfo, setTemplateListInfo] = useState([]);
  const [clients, setClients] = useState([]);
  const [options, setOptions] = useState([]);
  const [officers, setOfficers] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [servicesSelect, setServicesSelect] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateInfo, setSelectedTemplateInfo] = useState(null);
  const [serviceInfo, setServiceInfo] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [firmData, setFirmData] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [grossTotal, setGrossTotal] = useState(0);
  const [step, setStep] = useState("1");
  const [tax, setTax] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [editorText, setEditorText] = useState(
    RichTextEditor.createEmptyValue()
  );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [showForm, setShowForm] = useState("config");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [attachLetter, setAttachLetter] = useState(true);
  const [useOfficer, setUseOfficer] = useState(false);
  const [clientListData, setClientListData] = useContext(ClientContext);
  const [templateListData, setTemplateListData] = useContext(TemplateContext);
  const [subscriptionData, setSubscriptionData] =
    useContext(SubscriptionContext);
  const navigate = useNavigate();
  const searchInput = useRef();
  const searchInput2 = useRef();
  // const options = [
  //   { name: "Workshop One", value: "1" },
  //   { name: "Workshop Two", value: "2" },
  //   { name: "Workshop Three", value: "3" },
  //   { name: "Workshop Four", value: "4" },
  //   { name: "Workshop Five", value: "5" },
  // ];

  useEffect(() => {
    fetchData();
  }, [clientListData, templateListData]);

  useEffect(() => {
    if (showForm === "form") {
      setPreviewData();
    }
  }, [showForm]);

  useEffect(() => {
    if (servicesSelect.length) {
      let totalAmount = 0;
      let grossTotal = 0;
      let taxValueData = 0;
      const services = [...servicesSelect];
      services.forEach((item) => {
        if (item.value.selected) {
          totalAmount =
            totalAmount +
            Number(Number(item.value.price) * Number(item.value.unit));
        }
      });
      setTotalAmount(totalAmount);
      grossTotal = grossTotal =
        totalAmount + Number(Number(totalAmount * tax) / 100);
      setGrossTotal(grossTotal);
      taxValueData = Number(totalAmount * tax) / 100;
      setTaxValue(taxValueData);
    }
    if (servicesSelect.length === 0) {
      setTotalAmount(0);
      setGrossTotal(0);
      setTaxValue(0);
    }
  }, [servicesSelect, tax]);

  const fetchData = () => {
    getFirmTax();
    getProposalList();
    getFirmClients();
    getServices();
  };

  const checkSubscription = async () => {
    const subscriptionData = await getObjectData("subscriptionData");
    if (subscriptionData) {
      const present = moment(new Date());
      const createdDate = moment(new Date(subscriptionData.created_at));
      const daysDiff = present.diff(createdDate, "days");

      const remainingDays = subscriptionData.dayCredits - daysDiff;
      if (
        remainingDays > 0 &&
        subscriptionData &&
        subscriptionData.proposalCredits > 0
      ) {
        setVisible(!visible);
        return;
      }
    }
    swal({
      icon: "warning",
      title: !subscriptionData
        ? "No Subscription Found!"
        : subscriptionData.proposalCredits > 0
        ? "No subscription found"
        : "Proposals limit exhausted!",
      text: "You can subscribe from the subscription options available.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Subscribe Now",
      buttons: ["Cancel", "Subscribe Now"],
    }).then(async (value) => {
      if (value) {
        navigate("/subscription");
        swal.close();
      } else {
        swal.close();
        return;
      }
    });
  };

  const setPreviewData = async () => {
    const tokenData = await getObjectData("auth");
    const userData = jwt(tokenData.accessToken);
    if (userData) {
      const firmData = await getFirmData(userData.id);
      if (firmData) {
        let services = [];
        if (servicesSelect.length) {
          servicesSelect.forEach((item) => {
            if (item.value.selected) {
              item.value.price = Number(item.value.price);
              services.unshift(item.value);
            }
          });
        }
        let docData = {
          startDate: moment(startDate).format("DD-MM-YYYY"),
          endDate: moment(endDate).format("DD-MM-YYYY"),
          senderEmail: userData.email,
          receiverEmail: selectedClient ? selectedClient.user.email : "",
          senderName: `${userData.firstName} ${userData.lastName}`,
          receiverName:
            useOfficer && selectedClient && selectedOfficer
              ? `${selectedOfficer.name}`
              : selectedClient
              ? `${selectedClient.user.firstName} ${selectedClient.user.lastName}`
              : "",
          senderPost: "firm",
          receiverPost:
            useOfficer && selectedOfficer
              ? selectedOfficer.officerRole
              : "client",
          companyName:
            selectedClient && selectedClient.companyRegisteredName
              ? selectedClient.companyRegisteredName
              : "Sofyrus Technologies",
          logoUrl: firmData.logoUrl ? firmData.logoUrl : null,
          colorScheme:
            firmData && firmData.colorScheme ? firmData.colorScheme : null,
          proposalCode:
            firmData && firmData.companyCode
              ? firmData.companyCode + "XXXX"
              : "#MEEMXXXX",
          services,
          tax: tax,
          taxValue: taxValue,
          firmIntroduction: firmData.firmIntroduction
            ? firmData.firmIntroduction
            : null,
        };
        setTimeout(() => {
          document.getElementById("idoc").contentWindow.postMessage(docData);
        }, 1000);
      }
    }
  };

  const getFirmTax = async () => {
    try {
      const tokenData = await getObjectData("auth");
      const userData = jwt(tokenData.accessToken);
      const response = await authService.getFirmProfileUser(userData.id);
      if (response.status === 200) {
        const firmData = { ...response.data };
        setFirmData(firmData);
        setTax(Number(firmData.vat).toFixed(2));
      } else {
        setTax(0.0);
      }
    } catch (error) {
      setTax(0.0);
    }
  };

  const getFirmData = async (firmProfileId) => {
    try {
      const response = await authService.getFirmProfileUser(firmProfileId);
      if (response.status === 200) {
        const firmData = { ...response.data };
        if (firmData.logoUrl.includes("http")) {
          setProfileImage(firmData.logoUrl);
        } else getProfileImage(firmData.logoUrl);
        return firmData;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const getProfileImage = async (imageUrl) => {
    try {
      const response = await ProfileService.getProfileImage({ imageUrl });
      if (response.status === 201) {
        const base64 = btoa(
          new Uint8Array(response.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setProfileImage("data:;base64," + base64);
      } else {
        setProfileImage(null);
      }
    } catch (error) {
      setProfileImage(null);
    }
  };

  const getProposalList = async () => {
    try {
      setLoading(true);
      const response = await DocumentsServices.getProposalList();
      if (response.status === 200 && response.data.length) {
        let templates = [...response.data];
        templates = templates.map((ele) => {
          return { label: ele.title, value: { ...ele } };
        });
        setTemplateListInfo(templates);
        setTemplateList(response.data);
        setLoading(false);
      } else {
        setTemplateListInfo([]);
        setTemplateList([]);
        setLoading(false);
      }
    } catch (error) {
      setTemplateListInfo([]);
      setTemplateList([]);
      setLoading(false);
    }
  };

  const getFirmClients = async () => {
    try {
      const response = await ClientsServices.getFirmClients();
      if (response.status === 200 && response.data.length) {
        setClients(response.data);
        let clientData = [...response.data];
        clientData = clientData.map((item) => {
          return {
            name: `${item.user.firstName} ${item.user.lastName}`,
            value: item.id,
          };
        });
        setOptions(clientData);
      } else {
        setClients([]);
        setOptions([]);
      }
    } catch (error) {
      setClients([]);
      setOptions([]);
    }
  };

  const getClientOfficers = async (clientProfileId) => {
    try {
      const response = await ClientsServices.getClientOfficers(clientProfileId);
      if (response.status === 200 && response.data.length) {
        let officersData = [...response.data];
        setOfficers([...response.data]);
        officersData = officersData.map((item) => {
          return {
            name: `${item.name} (${item.officerRole})`,
            value: item.id,
          };
        });
        setOptions2(officersData);
      } else {
        setOfficers([]);
        setOptions2([]);
        setUseOfficer(false);
      }
    } catch (error) {
      setOfficers([]);
      setOptions2([]);
      setUseOfficer(false);
    }
  };

  const getServices = async () => {
    try {
      const response = await DocumentsServices.getServices();
      if (response.status === 200 && response.data.length) {
        let servicesCategory = [...response.data];
        let services = [];
        let selectedServices = [];
        let totalAmount = 0;
        servicesCategory = servicesCategory.map((ele) => {
          ele.services = ele.services.map((item, index) => {
            if (index === 0)
              return {
                label: item,
                value: {
                  ...item,
                  unit: 1,
                  lineTotal: item.price * 1,
                  selected: true,
                },
              };
            else
              return {
                label: item,
                value: {
                  ...item,
                  unit: 1,
                  lineTotal: item.price * 1,
                  selected: false,
                },
              };
          });
          ele.services.forEach((item) => {
            totalAmount =
              totalAmount + Number(Number(item.price) * Number(item.unit));
            if (item.value.selected) selectedServices.push(item);
            else services.push(item);
          });
          return { label: ele.title, value: ele };
        });
        setTotalAmount(totalAmount);
        setServicesList(servicesCategory);
        setServicesData(services);
        setSelectedServices(servicesCategory);
      } else {
        setServicesList([]);
      }
    } catch (error) {
      setServicesList([]);
    }
  };

  const handleChange = (value) => {
    const clientList = [...clients];
    const clientData = clientList.find((item) => item.id === value);
    setSelectedClient(clientData);
    getClientOfficers(clientData.id);
  };

  const handleOfficerChange = (value) => {
    const officerList = [...officers];
    const officerData = officerList.find((item) => item.id === value);
    setSelectedOfficer(officerData);
  };

  const onChangeHandler = (index, name, value) => {
    const services = [...servicesSelect];
    services[index].value[name] = value;
    services[index].value["lineTotal"] =
      Number(services[index].value["price"]) *
      Number(services[index].value["unit"]);
    setServicesSelect(services);
  };

  const selectChangeHandler = (item, index, value) => {
    const selected = [...servicesSelect];
    const notSelected = [...servicesData];
    if (value) {
      item.value.selected = true;
      selected.forEach((ele, index) => {
        if (ele.value.id === item.value.id) selected.splice(index, 1);
      });
      selected.push(item);
      notSelected.forEach((ele, index) => {
        if (ele.value.id === item.value.id) notSelected.splice(index, 1);
      });
      setServicesSelect(selected);
      setServicesData(notSelected);
    } else {
      item.value.selected = false;
      notSelected.forEach((ele, index) => {
        if (ele.value.id === item.value.id) notSelected.splice(index, 1);
      });
      notSelected.push(item);
      selected.forEach((ele, index) => {
        if (ele.value.id === item.value.id) selected.splice(index, 1);
      });
      setServicesSelect(selected);
      setServicesData(notSelected);
    }
  };

  // const options = [
  //   { name: "Workshop Three", value: "1" },
  //   { name: "Workshop Two", value: "2" },
  //   { name: "Workshop Three", value: "3" },
  //   { name: "Workshop Four", value: "4" },
  //   { name: "Workshop Five", value: "5" }
  // ];

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return options;
      }
      const updatedItems = items.filter((list) => {
        return list.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      return updatedItems;
    };
  };

  const handleFilter2 = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return options2;
      }
      const updatedItems = items.filter((list) => {
        return list.name.toLowerCase().includes(searchValue.toLowerCase());
      });
      return updatedItems;
    };
  };

  const sendLetterOfEngagement = async (proposalId) => {
    try {
      // setLoader(true);
      let services = [];
      if (servicesData.length) {
        servicesData.forEach((item) => {
          if (item.value.selected) {
            item.value.price = Number(item.value.price);
            item.value.unit = Number(item.value.unit);
            item.value.lineTotal = Number(item.value.lineTotal);
            services.unshift(item.value);
          }
        });
      }
      let payload = {
        clientProfileId: selectedClient.id,
        engagementFormatId: selectedTemplate.id,
        proposalId: proposalId,
        engagementContent: {
          company:
            selectedClient && selectedClient.companyRegisteredName
              ? selectedClient.companyRegisteredName
              : "",
          startDate: moment(startDate).format("DD-MM-YYYY"),
          endDate: moment(endDate).format("DD-MM-YYYY"),
          services,
          tax,
          taxValue,
        },
      };
      const response = await DocumentsServices.sendLetterOfEngagement(payload);
      if (response.status === 201) {
        // savePdf(response.data, body, head);
        // setLoader(false);
        // setVisible(false);
        // setShowForm("config");
        // setStep("1");
        // setSelectedClient(null);
        // setSelectedTemplate(null);
        toast.success("Letter of engagement sent successfully to email.");
      } else {
        // setLoader(false);
        toast.error("Unable to send Letter of engagement!");
      }
    } catch (error) {
      // setLoader(false);
      toast.error("Unable to send the Letter of engagement!");
    }
  };

  const sendDocument = async (body, head) => {
    try {
      setLoader(true);
      let services = [];
      let selectedServiceCategory = [];
      if (servicesSelect.length) {
        servicesSelect.forEach((item) => {
          if (item.value.selected) {
            item.value.price = Number(item.value.price);
            item.value.unit = Number(item.value.unit);
            item.value.lineTotal = Number(item.value.lineTotal);
            services.unshift(item.value);
            if (item.value.categoryId) {
              let check = selectedServiceCategory.find(
                (ele) => ele === item.value.categoryId
              );
              if (!check) selectedServiceCategory.push(item.value.categoryId);
            }
          }
        });
      }
      let servicesCategory = servicesList.filter((ele) =>
        selectedServiceCategory.includes(ele.value.id)
      );
      servicesCategory = servicesCategory.map((ele) => {
        return {
          title: ele.value.title,
          termConditions: ele.value.termConditions,
        };
      });
      let payload = {
        clientProfileId: selectedClient.id,
        proposalFormatId: selectedTemplate.id,
        proposalContent: {
          company:
            selectedClient && selectedClient.companyRegisteredName
              ? selectedClient.companyRegisteredName
              : "",
          startDate: moment(startDate).format("DD-MM-YYYY"),
          endDate: moment(endDate).format("DD-MM-YYYY"),
          services,
          tax,
          taxValue,
          officer:
            useOfficer && selectedOfficer && selectedOfficer.name
              ? selectedOfficer.name
              : null,
          officerRole:
            useOfficer && selectedOfficer ? selectedOfficer.officerRole : null,
          priceOptions:
            selectedTemplate && selectedTemplate.priceOptionsIds
              ? selectedTemplate.priceOptionsIds
              : { priceOptionsIds: [] },
          servicesCategory,
        },
        letterOfEngagement: attachLetter,
      };
      const response = await DocumentsServices.sendDocument(payload);
      if (response.status === 201) {
        // if (attachLetter) {
        //   sendLetterOfEngagement(response.data.id);
        // }
        // savePdf(response.data, body, head);
        setLoader(false);
        setVisible(false);
        setShowForm("config");
        setStep("1");
        setSelectedClient(null);
        setSelectedOfficer(null);
        setSelectedTemplate(null);
        setUseOfficer(false);
        setOptions2([]);
        toast.success("Document sent successfully to email.");
      } else {
        setLoader(false);
        toast.error("Unable to send the document!");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Unable to send the document!");
    }
  };

  const saveDraft = async () => {
    try {
      setLoader(true);
      let services = [];
      let selectedServiceCategory = [];
      if (servicesSelect.length) {
        servicesSelect.forEach((item) => {
          if (item.value.selected) {
            item.value.price = Number(item.value.price);
            item.value.unit = Number(item.value.unit);
            item.value.lineTotal = Number(item.value.lineTotal);
            services.unshift(item.value);
            if (item.value.categoryId) {
              let check = selectedServiceCategory.find(
                (ele) => ele === item.value.categoryId
              );
              if (!check) selectedServiceCategory.push(item.value.categoryId);
            }
          }
        });
      }
      let servicesCategory = servicesList.filter((ele) =>
        selectedServiceCategory.includes(ele.value.id)
      );
      servicesCategory = servicesCategory.map((ele) => {
        return {
          title: ele.value.title,
          termConditions: ele.value.termConditions,
        };
      });
      let payload = {
        clientProfileId: selectedClient.id,
        proposalFormatId: selectedTemplate.id,
        proposalContent: {
          company:
            selectedClient && selectedClient.companyRegisteredName
              ? selectedClient.companyRegisteredName
              : "",
          startDate: moment(startDate).format("DD-MM-YYYY"),
          endDate: moment(endDate).format("DD-MM-YYYY"),
          services,
          tax,
          taxValue,
          officer:
            useOfficer && selectedOfficer && selectedOfficer.name
              ? selectedOfficer.name
              : null,
          officerRole:
            useOfficer && selectedOfficer ? selectedOfficer.officerRole : null,
          priceOptions:
            selectedTemplate && selectedTemplate.priceOptionsIds
              ? selectedTemplate.priceOptionsIds
              : { priceOptionsIds: [] },
          servicesCategory,
        },
        letterOfEngagement: attachLetter,
      };
      const response = await DocumentsServices.saveDocument(payload);
      if (response.status === 201) {
        // if (attachLetter) {
        //   sendLetterOfEngagement(response.data.id);
        // }
        // savePdf(response.data, body, head);
        setLoader(false);
        setVisible(false);
        setShowForm("config");
        setStep("1");
        setSelectedClient(null);
        setSelectedOfficer(null);
        setSelectedTemplate(null);
        setUseOfficer(false);
        setOptions2([]);
        toast.success("Document saved successfully.");
      } else {
        setLoader(false);
        toast.error("Unable to save the document!");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Unable to save the document!");
    }
  };

  const savePdf = async (proposalData, body, head) => {
    try {
      const data = head + body;
      const response = await DocumentsServices.savePdf({
        pdfhtml: data,
        proposalData,
        firmCode: firmData.companyCode,
      });
    } catch (error) {}
  };

  const onChange = (value) => {
    setEditorText(value);
    const data = { ...serviceInfo };
    data.description = value.toString("html");
    setServiceInfo(data);
  };

  const previewModal = (props) => {
    return (
      <iframe
        id="idoc"
        title={"pdf"}
        allowFullScreen
        frameBorder="0"
        height="480px"
        src={"/pdf.html"}
        width="100%"
      />
    );
  };

  const settingModal = () => {
    return (
      <>
        <div className="input-floating-label">
          <Select
            className="client-type"
            options={servicesList}
            isSearchable
            value={selectedServices}
            styles={{
              container: () => ({
                width: "100%",
              }),
              menu: (styles) => ({
                ...styles,
                zIndex: 7777,
              }),
            }}
            onChange={(item) => {
              let servicesInfo = [];
              let selected = [];
              let selectedArray = [...servicesSelect];
              if (!selectedArray.length) {
                item.value.services.forEach((item) => {
                  if (item.value.selected) selected.push(item);
                  else servicesInfo.push(item);
                });
                selectedArray = [...selected];
              } else {
                item.value.services.forEach((item) => {
                  const check = selectedArray.find(
                    (ele) => ele.value.id === item.value.id
                  );
                  if (!check && item.value.selected) {
                    selected.push(item);
                  } else servicesInfo.push(item);
                });
                selectedArray = [...selectedArray, ...selected];
              }
              setServicesSelect([...selectedArray]);
              setServicesData([...servicesInfo]);
              setSelectedServices({ ...item });
            }}
          />
          <label>Services</label>
          <span className="focus-bg"></span>
        </div>
        <div style={{ display: "flex", marginBottom: 30 }}>
          <p style={{ fontSize: 18, fontWeight: "600", width: "15%" }}>
            {" "}
            Selected Services
          </p>
          <hr
            style={{
              width: "83%",
              alignSelf: "center",
              marginLeft: 5,
              color: "black",
            }}
          />
        </div>
        {servicesSelect.length
          ? servicesSelect.map((item, index) => {
              if (item.value.selected)
                return (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="input-floating-label"
                      style={{ width: "40%" }}
                    >
                      <input
                        className="input-client"
                        value={item.value.title}
                        type="text"
                        id="title"
                        name="title"
                        placeholder="Service Title"
                        onChange={(e) =>
                          onChangeHandler(index, e.target.name, e.target.value)
                        }
                      />
                      <label>Service Title</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.unit)}
                        type="number"
                        id="unit"
                        name="unit"
                        placeholder="Unit"
                        onChange={(e) =>
                          onChangeHandler(
                            index,
                            e.target.name,
                            Number(e.target.value)
                          )
                        }
                      />
                      <label>Unit</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.price)}
                        type="number"
                        id="price"
                        name="price"
                        placeholder="Price"
                        onChange={(e) =>
                          onChangeHandler(
                            index,
                            e.target.name,
                            Number(e.target.value)
                          )
                        }
                      />
                      <label>Price</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.lineTotal)}
                        type="text"
                        id="lineTotal"
                        name="lineTotal"
                        placeholder="Total"
                        disabled
                      />
                      <label>Total</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div style={{ marginRight: 15, marginTop: 5 }}>
                      {item.value.selected ? (
                        <BsCheckCircleFill
                          size={30}
                          title="Deselect this service"
                          color={"#00699f"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            selectChangeHandler(item, index, false);
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              else return null;
            })
          : null}

        {servicesData.length ? (
          <div style={{ display: "flex", marginBottom: 30 }}>
            <p style={{ fontSize: 18, fontWeight: "600" }}>Services</p>
            <hr
              style={{
                width: "100%",
                alignSelf: "center",
                marginLeft: 5,
                color: "black",
              }}
            />
          </div>
        ) : null}
        {servicesData.length
          ? servicesData.map((item, index) => {
              if (!item.value.selected)
                return (
                  <div
                    key={index}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="input-floating-label"
                      style={{ width: "40%" }}
                      onClick={() => alert("Please select this service first!")}
                    >
                      <input
                        className="input-client"
                        value={item.value.title}
                        type="text"
                        id="title"
                        name="title"
                        disabled
                        placeholder="Service Title"
                      />
                      <label>Service Title</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                      onClick={() => alert("Please select this service first!")}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.unit)}
                        type="text"
                        id="unit"
                        name="unit"
                        placeholder="Unit"
                        disabled
                      />
                      <label>Unit</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                      onClick={() => alert("Please select this service first!")}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.price)}
                        type="text"
                        id="price"
                        name="price"
                        placeholder="Price"
                        disabled
                      />
                      <label>Price</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div
                      className="input-floating-label"
                      style={{ width: "15%" }}
                    >
                      <input
                        className="input-client"
                        value={String(item.value.lineTotal)}
                        type="text"
                        id="lineTotal"
                        name="lineTotal"
                        placeholder="Total"
                        disabled
                      />
                      <label>Total</label>
                      <span className="focus-bg"></span>
                    </div>
                    <div style={{ marginRight: 15, marginTop: 5 }}>
                      {item.value.selected ? null : (
                        <BsCheckCircle
                          size={30}
                          title="Select this service"
                          color={"#00699f"}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            selectChangeHandler(item, index, true);
                          }}
                        />
                      )}
                    </div>
                    {/* <div>
                  <CButton
                    style={{ background: "#e55353" }}
                    color="danger"
                    onClick={() => {
                      const service = [...servicesData];
                      const list = [...selectedServices];
                      service.splice(index, 1);
                      list.splice(index, 1);
                      setServicesData(service);
                      setSelectedServices(list);
                    }}
                  >
                    <CIcon style={{ color: "#fff" }} icon={cilTrash} />
                  </CButton>
                </div> */}
                  </div>
                );
              else return null;
            })
          : null}
        <div style={{ display: "flex", marginBottom: 30, marginTop: 15 }}>
          <p style={{ fontSize: 18, fontWeight: "600" }}>Net Amount</p>
          <hr
            style={{
              width: "88%",
              alignSelf: "center",
              marginLeft: 5,
              color: "black",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div className="w-25">
            <label className="modal-label">Total Amount</label>
          </div>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={String(totalAmount)}
              type="text"
              disabled={true}
              id="totalAmount"
              name="totalAmount"
            />
            <span className="focus-bg"></span>
          </div>
          <div className="w-25">
            <label className="modal-label">Tax Percent</label>
          </div>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={tax}
              type="number"
              id="tax"
              name="tax"
              onChange={(e) => {
                setTax(Number(e.target.value));
              }}
            />
            <span className="focus-bg"></span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div className="w-25">
            <label className="modal-label">Tax Amount</label>
          </div>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={String(taxValue)}
              type="text"
              disabled={true}
              id="taxValue"
              name="taxValue"
            />
            <span className="focus-bg"></span>
          </div>
          <div className="w-25">
            <label className="modal-label">Gross Total</label>
          </div>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={String(grossTotal)}
              type="text"
              disabled={true}
              id="grossTotal"
              name="grossTotal"
            />
            <span className="focus-bg"></span>
          </div>
        </div>
        <div style={{ display: "flex", marginBottom: 30 }}>
          <p style={{ fontSize: 18, fontWeight: "600" }}>Custom Services</p>
          <hr
            style={{
              width: "86%",
              alignSelf: "center",
              marginLeft: 5,
              color: "black",
            }}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={serviceInfo ? serviceInfo.title : ""}
              type="text"
              id="title"
              name="title"
              // placeholder="Service Title"
              style={{ color: "black" }}
              onChange={(e) => {
                setServiceInfo({
                  ...serviceInfo,
                  [e.target.name]: e.target.value,
                });
              }}
            />
            <label>Service Title</label>
            <span className="focus-bg"></span>
          </div>
          <div className="input-floating-label">
            <input
              className="input-client"
              value={
                serviceInfo && serviceInfo.price
                  ? String(serviceInfo.price)
                  : "0"
              }
              type="number"
              id="price"
              name="price"
              // placeholder="Price"
              onChange={(e) => {
                setServiceInfo({
                  ...serviceInfo,
                  [e.target.name]: Number(e.target.value),
                });
              }}
            />
            <label>Price</label>
            <span className="focus-bg"></span>
          </div>
        </div>
        <div className="f-editor">
          <RichTextEditor
            editorStyle={{ height: 200, width: "100%" }}
            value={editorText}
            onChange={onChange}
          />
        </div>
        <div style={{ marginTop: 15, textAlign: "end", width: "100%" }}>
          <CButton
            color="primary"
            onClick={() => {
              const service = [...servicesSelect];
              // const list = [...servicesList];
              // const selected = [...selectedServices];
              const data = {
                label: serviceInfo.title,
                value: {
                  ...serviceInfo,
                  unit: 1,
                  lineTotal: serviceInfo.price * 1,
                  selected: true,
                },
              };
              // list.push(data);
              // setServicesList(list);
              service.push(data);
              setServicesSelect(service);
              setServiceInfo(null);
              setEditorText(RichTextEditor.createEmptyValue());
              // selected.push(data);
              // setSelectedServices(selected);
            }}
          >
            Add Service
          </CButton>
        </div>
      </>
    );
  };

  return (
    <CHeader position="sticky">
      <CModal
        className="modal-xl"
        alignment="center"
        visible={visible}
        onClose={() => {
          setShowForm("config");
          setVisible(false);
          setSelectedClient(null);
          setSelectedTemplate(null);
          setSelectedOfficer(null);
          setUseOfficer(false);
          setOptions2([]);
          setServicesData([]);
          setServicesSelect([]);
          setSelectedTemplateInfo(null);
          fetchData();
          setStep("1");
          setServiceInfo(null);
          setEditorText(RichTextEditor.createEmptyValue());
        }}
      >
        <CModalHeader closeButton={false}>
          <CModalTitle>
            {showForm === "form"
              ? "Document Preview"
              : showForm === "config"
              ? "Create Proposal"
              : "Select Services"}
          </CModalTitle>
          <div
            style={{
              width: showForm === "form" ? "60%" : "90%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <div
              style={{
                backgroundColor: "rgb(31, 123, 169)",
                width: 105,
                textAlign: "center",
                borderRadius: 5,
              }}
            >
              <p
                style={{
                  fontSize: 18,
                  color: "white",
                }}
              >
                {" "}
                Step: {step}/3
              </p>
            </div>
          </div>
          {showForm === "form" && (
            <div>
              <CButton
                color="info"
                onClick={() => {
                  saveDraft();
                }}
                style={{ justifyItems: "flex-end" }}
              >
                Save As Draft
              </CButton>
            </div>
          )}
        </CModalHeader>
        <CModalBody>
          {showForm === "config" ? (
            <div>
              <CContainer>
                <div style={{ display: "flex" }}>
                  <div>
                    <h1
                      style={{
                        fontSize: "24px",
                        margin: "16px 0 0 0",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      Add Recipient
                    </h1>
                    <p>Who will be recieving the documents?</p>
                  </div>
                  <div style={{ alignSelf: "center", marginLeft: "auto" }}>
                    <CButton
                      color="primary"
                      style={{ marginTop: 13, fontSize: 14 }}
                      onClick={() => {
                        setVisible(false);
                        setSelectedClient(null);
                        setSelectedTemplate(null);
                        setSelectedOfficer(null);
                        setUseOfficer(false);
                        setOptions2([]);
                        navigate("/client");
                      }}
                    >
                      <CIcon icon={cilUserPlus} style={{ marginRight: 5 }} />
                      Add New Recepient
                    </CButton>
                  </div>
                </div>
                <hr />
                <div className="d-flex w-100 justify-content-between">
                  <div
                    className="rec-component w-100 tem-section"
                    style={{ textAlign: "left" }}
                  >
                    <label>Client : </label>
                    <div style={{ marginTop: 5 }}>
                      <SelectSearch
                        ref={searchInput}
                        options={options}
                        filterOptions={handleFilter}
                        value={selectedClient && selectedClient.id}
                        name="Client List"
                        placeholder="Search"
                        search
                        onChange={(value) => {
                          handleChange(value);
                        }}
                      />
                    </div>
                    {options2.length ? (
                      <div style={{ marginTop: 10 }}>
                        <CFormCheck
                          label="Use Officer Detail for proposal"
                          onClick={(e) => {
                            setUseOfficer(e.target.checked);
                          }}
                        />
                      </div>
                    ) : null}
                    {useOfficer && (
                      <>
                        <label style={{ marginTop: 10 }}>
                          Client Officer :{" "}
                        </label>
                        <div style={{ marginTop: 5 }}>
                          <SelectSearch
                            ref={searchInput2}
                            options={options2}
                            filterOptions={handleFilter2}
                            value={selectedOfficer && selectedOfficer.id}
                            name="Client List"
                            placeholder="Search Officers"
                            search
                            onChange={(value) => {
                              handleOfficerChange(value);
                            }}
                          />
                        </div>
                      </>
                    )}
                    <div className="date-time text-left mt-2">
                      <label className="mb-2">Start Date : </label>
                      <DatePicker
                        selected={startDate}
                        showYearDropdown
                        showMonthDropdown
                        onChange={(date) => setStartDate(date)}
                      />
                      <label className="mb-2">End Date : </label>
                      <DatePicker
                        selected={endDate}
                        showYearDropdown
                        showMonthDropdown
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                    <div style={{ padding: 5 }}>
                      <h1
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "#fff",
                          padding: "15px",
                          backgroundColor: "#207ba9",
                          borderRadius: "8px",
                          textAlign: "left",
                        }}
                      >
                        Selected Client
                      </h1>
                      <hr className="mb-3" />
                      <div className="w-100 m-profile d-flex align-items-center p-4 justify-content-around">
                        <div className="m-profile2">
                          <CIcon
                            icon={cilUser}
                            customClassName="nav-icon icon-md"
                          />
                        </div>
                        <div className="pro-info1" style={{ width: "80%" }}>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ borderBottomWidth: 1, paddingBottom: 6 }}
                          >
                            <label>Name :</label>
                            <h3>
                              {selectedClient &&
                                `${selectedClient.user.firstName} ${selectedClient.user.lastName}`}
                            </h3>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ borderBottomWidth: 1, paddingBottom: 6 }}
                          >
                            <label>Email :</label>
                            <h3>
                              {selectedClient && selectedClient.user.email}
                            </h3>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ borderBottomWidth: 1, paddingBottom: 6 }}
                          >
                            <label>Company number :</label>
                            <h3>
                              {selectedClient &&
                                selectedClient.companyRegisteredNo}
                            </h3>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ borderBottomWidth: 1, paddingBottom: 6 }}
                          >
                            <label>Company Address :</label>
                            <h3>
                              {selectedClient &&
                                selectedClient.companyRegisteredAddress}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <CRow className="mb-3" xs={{ gutter: 6 }}>
                  <CCol xs={{ span: 12 }}>
                    <div className="tem-section mb-3">
                      <h1
                        style={{
                          fontSize: "18px",
                          fontWeight: "600",
                          color: "black",
                          paddingBottom: "15px",
                        }}
                      >
                        Select Templates
                      </h1>
                      <hr className="mb-3" />
                      {loading && (
                        <div style={{ padding: 15, textAlign: "center" }}>
                          <InfinitySpin
                            height="180"
                            width="180"
                            radius="9"
                            color="#00699f"
                            ariaLabel="Loading...."
                            wrapperStyle
                            wrapperClass
                          />
                          <h1>Loading Clients ...</h1>
                        </div>
                      )}
                      <div className="input-floating-label">
                        <Select
                          className="client-type"
                          options={templateListInfo}
                          isSearchable
                          value={selectedTemplateInfo}
                          styles={{
                            container: () => ({
                              width: "100%",
                            }),
                            menu: (styles) => ({
                              ...styles,
                              zIndex: 7777,
                            }),
                          }}
                          onChange={(item) => {
                            setSelectedTemplate(item.value);
                            setSelectedTemplateInfo(item);
                            const servicesData = [...servicesList];
                            let servicesVal = [];

                            servicesData.forEach((ele) => {
                              const valueArray = ele.value.services.filter(
                                (e) =>
                                  item.value.proposalServicesIds.servicesIds.includes(
                                    e.value.id
                                  )
                              );
                              servicesVal = [...servicesVal, ...valueArray];
                            });
                            if (servicesVal.length) {
                              setSelectedServices([]);
                              const selected = [];
                              const notSelected = [];
                              servicesVal.forEach((ele) => {
                                if (ele.value.selected) selected.push(ele);
                                else notSelected.push(ele);
                              });
                              setServicesSelect(selected);
                              setServicesData(notSelected);
                            } else {
                              setSelectedServices([]);
                              setServicesData([]);
                              setServicesSelect([]);
                            }
                          }}
                        />
                        <label>Templates</label>
                        <span className="focus-bg"></span>
                      </div>
                      <div
                        className="d-flex mb-4"
                        style={{ justifyContent: "flex-end" }}
                      >
                        <CFormSwitch
                          label="Attach Letter Of Engagement"
                          defaultChecked={attachLetter}
                          size="xl"
                          style={{ width: 32 }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setAttachLetter(true);
                            } else setAttachLetter(false);
                          }}
                        />
                      </div>
                      {selectedTemplate ? (
                        <CRow xs={{ gutter: 2 }}>
                          <CCol xs={{ span: 12 }}>
                            <div style={{ padding: 5 }}>
                              <h1
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "600",
                                  color: "#fff",
                                  padding: "15px",
                                  backgroundColor: "#207ba9",
                                  borderRadius: "8px",
                                }}
                              >
                                Selected Template
                              </h1>
                              <hr className="mb-3" />
                              <div className="d-flex align-items-center m-0 justify-content-around">
                                <div className="m-profile1">
                                  <CIcon
                                    icon={cilNotes}
                                    customClassName="nav-icon icon-md"
                                  />
                                </div>
                                <div
                                  className="pro-info"
                                  style={{ width: "60%" }}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      borderBottomWidth: 1,
                                      paddingBottom: 6,
                                    }}
                                  >
                                    <label>Template Title :</label>
                                    <h3>
                                      {selectedTemplate &&
                                        selectedTemplate.title}
                                    </h3>
                                  </div>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      borderBottomWidth: 1,
                                      paddingBottom: 6,
                                    }}
                                  >
                                    <label>Template Name :</label>
                                    <h3>
                                      {selectedTemplate &&
                                        selectedTemplate.proposalFileName}
                                    </h3>
                                  </div>
                                </div>
                                <div style={{ width: "20%" }}>
                                  <div
                                    className="p-3 border bg-white doc-view"
                                    style={{
                                      backgroundImage: `url(${defaultImg})`,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                      backgroundRepeat: "no-repeat",
                                    }}
                                  ></div>
                                  <div className="d-flex justify-content-center p-2">
                                    <h1
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        color: "black",
                                      }}
                                    >
                                      Screen Shot
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CCol>
                        </CRow>
                      ) : (
                        <CRow xs={{ gutter: 2 }}>
                          <CCol>
                            <div
                              className="text-center"
                              style={{ padding: 15 }}
                            >
                              <img
                                src={notFound}
                                style={{ margin: "auto", width: "100px" }}
                              />
                              <h2
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "500",
                                  color: "black",
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                  textAlign: "center",
                                }}
                              >
                                No Template Selected
                              </h2>
                            </div>
                          </CCol>
                        </CRow>
                      )}
                    </div>
                  </CCol>
                </CRow>
              </CContainer>
            </div>
          ) : showForm === "form" ? (
            previewModal({ src: "https://www.youtube.com/embed/OZWRM2WG8Kg" })
          ) : (
            settingModal()
          )}
        </CModalBody>
        <CModalFooter className="modal-btn">
          <div className="d-flex justify-content-between w-100">
            {/* <div>
              <CButton color="secondary" onClick={() => setVisible(false)}>
                Add Recipient
              </CButton>
            </div> */}
            {loader ? (
              <div style={{ marginLeft: "auto" }}>
                <Bars
                  height="40"
                  width="40"
                  radius="9"
                  color="#00699f"
                  ariaLabel="Loading...."
                  wrapperStyle={{ justifyContent: "flex-end" }}
                  wrapperClass
                />
              </div>
            ) : (
              <div
                className="d-flex justify-content-between w-50"
                style={{ marginLeft: "auto" }}
              >
                {showForm === "config" ? (
                  <CButton
                    color="secondary"
                    onClick={() => {
                      setVisible(false);
                      setSelectedClient(null);
                      setSelectedTemplate(null);
                      setSelectedOfficer(null);
                      setUseOfficer(false);
                      setOptions2([]);
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Close
                  </CButton>
                ) : showForm === "form" ? (
                  <CButton
                    color="secondary"
                    onClick={() => {
                      setStep("2");
                      setShowForm("setting");
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Close Preview
                  </CButton>
                ) : (
                  <CButton
                    color="secondary"
                    onClick={() => {
                      setStep("1");
                      setShowForm("config");
                    }}
                    style={{ marginRight: "10px" }}
                  >
                    Go Back
                  </CButton>
                )}
                {showForm === "config" ? (
                  <CButton
                    color="primary"
                    onClick={() => {
                      if (startDate > endDate) {
                        alert(
                          "Start Date cannot be larger than End Date of document!"
                        );
                        return;
                      }
                      if (selectedTemplate && selectedClient) {
                        setStep("2");
                        setShowForm("setting");
                      } else {
                        alert(
                          "Select template and client for sending proposal!"
                        );
                      }
                    }}
                  >
                    Next
                  </CButton>
                ) : showForm === "form" ? (
                  <CButton
                    color="primary"
                    onClick={() => {
                      const iframe = document.getElementById("idoc");
                      const iframeDocument =
                        iframe.contentWindow.document.body.innerHTML;
                      const iframeDocumentHead =
                        iframe.contentWindow.document.head.innerHTML;
                      sendDocument(iframeDocument, iframeDocumentHead);
                    }}
                  >
                    Submit
                  </CButton>
                ) : (
                  <CButton
                    color="primary"
                    onClick={() => {
                      setStep("3");
                      setShowForm("form");
                    }}
                  >
                    Preview
                  </CButton>
                )}
              </div>
            )}
          </div>
        </CModalFooter>
      </CModal>
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          {/* <CIcon icon={logo} height={48} alt="Logo" /> */}
          <img src={logo} />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {/* <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink href="#">Settings</CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
            <button
              className="btn btn-primary"
              style={{ fontSize: 13 }}
              onClick={() => checkSubscription()}
            >
              <CIcon icon={cilDescription} size="sm" color="white" />
              {` Create Proposal`}
            </button>
          </CNavItem>
          <CNavItem>
            {/* <CNavLink href="#">
              <CIcon icon={cilList} size="lg" />
            </CNavLink> */}
          </CNavItem>
          <CNavItem>
            {/* <CNavLink href="#">
              <CIcon icon={cilEnvelopeOpen} size="lg" />
            </CNavLink> */}
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
