import React, { useEffect } from "react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CLink,
  CRow,
  CWidgetStatsB,
  CWidgetStatsC,
  CWidgetStatsE,
  CWidgetStatsF,
} from "@coreui/react";
import { getStyle } from "@coreui/utils";
import CIcon from "@coreui/icons-react";
import {
  cilArrowRight,
  cilBasket,
  cilBell,
  cilChartPie,
  cilMoon,
  cilLaptop,
  cilPeople,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
  cilNotes,
  cilChart,
  cilDataTransferUp,
  cilMagnifyingGlass,
} from "@coreui/icons";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import { DocsExample } from "src/components";

import WidgetsBrand from "./WidgetsBrand";
import WidgetsDropdown from "./WidgetsDropdown";
import { Link, useNavigate } from "react-router-dom";
import { BsHourglassSplit } from "react-icons/bs";
import { RiDraftFill } from "react-icons/ri";
import { HiDocumentText, HiUserCircle } from "react-icons/hi";
import { HiDocumentCheck, HiDocumentPlus } from "react-icons/hi2";

const CardCount = ({ type, data }) => {
  const navigate = useNavigate();

  if (type === "admin")
    return (
      <div>
        <div className="mb-5 d-flex justify-content-between">
          <div
            className="card-widget"
            style={{ backgroundColor: "#2761fe", width: "30%" }}
          >
            <div className="icon-wid">
              <HiDocumentText size={30} />
            </div>
            <div className="text-right">
              <p>Total Firms</p>
              <h2>{data && data.firms && data.firms ? data.firms : 0}</h2>
              <button
                style={{
                  marginTop: 5,
                  color: "#2761fe",
                  height: 28,
                  fontSize: 12,
                  display: "inline-flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  fontWeight: "600",
                }}
                className="btn"
                onClick={() => {
                  navigate("/adminFirms");
                }}
              >
                View Details
              </button>
            </div>
          </div>
          <div
            className="card-widget"
            style={{ backgroundColor: "#f3b929", width: "30%" }}
          >
            <div className="icon-wid">
              <HiDocumentCheck size={30} />
            </div>
            <div className="text-right">
              <p>Total Subscriptions</p>
              <h2>
                {data && data.subscriptions && data.subscriptions
                  ? data.subscriptions
                  : 0}
              </h2>
              <button
                style={{
                  marginTop: 5,
                  color: "#f3b929",
                  height: 28,
                  fontSize: 12,
                  display: "inline-flex",
                  alignItems: "center",
                  backgroundColor: "white",
                  fontWeight: "600",
                }}
                className="btn"
                onClick={() => {
                  navigate("/subscriptions");
                }}
              >
                View Details
              </button>
            </div>
          </div>
          <div
            className="card-widget"
            style={{ backgroundColor: "#fa1e68", width: "30%" }}
          >
            <div className="icon-wid">
              <HiDocumentPlus size={30} />
            </div>
            <div className="text-right">
              <p>Proposals</p>
              <h2>
                {data && data.proposals && data.proposals ? data.proposals : 0}
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  else
    return (
      <div>
        <div className="mb-5 d-flex justify-content-between flex-wrap">
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#a15a99" }}
          >
            <div className="icon-wid">
              <HiDocumentText size={30} />
            </div>
            <div className="text-right">
              <p> Total Proposals</p>
              <h2>
                {data && data.proposals && data.proposals.totalCount
                  ? data.proposals.totalCount
                  : 0}
              </h2>
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#c22fdb" }}
          >
            <div className="icon-wid">
              <RiDraftFill size={30} />
            </div>
            <div className="text-right">
              <p>Drafts</p>
              <h2>
                {data && data.proposals && data.proposals.draftCount
                  ? data.proposals.draftCount
                  : 0}
              </h2>
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#2761fe" }}
          >
            <div className="icon-wid">
              <BsHourglassSplit size={30} />
            </div>
            <div className="text-right">
              <p> Pending Proposals</p>
              <h2>
                {data && data.proposals && data.proposals.pendingCount
                  ? data.proposals.pendingCount
                  : 0}
              </h2>
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#f3b929" }}
          >
            <div className="icon-wid">
              <HiDocumentCheck size={30} />
            </div>
            <div className="text-right">
              <p>Accepted Proposals</p>
              <h2>
                {data && data.proposals && data.proposals.acceptedCount
                  ? data.proposals.acceptedCount
                  : 0}
              </h2>
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#fa1e68" }}
          >
            <div className="icon-wid">
              <HiDocumentPlus size={30} />
            </div>
            <div className="text-right">
              <p> Amendments Required</p>
              <h2>
                {data && data.proposals && data.proposals.amendmentCount
                  ? data.proposals.amendmentCount
                  : 0}
              </h2>
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#2e8f22" }}
          >
            <div className="icon-wid">
              <HiDocumentPlus size={30} />
            </div>
            <div className="text-right">
              <p> AnnualDue Client</p>
              <h2>
                {data && data.annualAccountDueCount
                  ? data.annualAccountDueCount
                  : 0}
              </h2>
              {data && data.annualAccountDueCount ? (
                <div>
                  <button
                    className="btn btn-success"
                    style={{ color: "white", height: 34, fontSize: 12 }}
                    onClick={() => {
                      navigate("/clientDueOn",{state:{type:'annualDueCount'}});
                    }}
                  >
                    View Clients
                  </button>
                </div>
              ) : null}              
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#2e8f22" }}
          >
            <div className="icon-wid">
              <HiDocumentPlus size={30} />
            </div>
            <div className="text-right">
              <p> VAT Due Clients</p>
              <h2>
                {data && data.annualVatDueCount
                  ? data.annualVatDueCount
                  : 0}
              </h2>
              {data && data.annualVatDueCount ? (
                <div>
                  <button
                    className="btn btn-success"
                    style={{ color: "white", height: 34, fontSize: 12 }}
                    onClick={() => {
                      navigate("/clientDueOn",{state: {type:'vatDueCount'}});
                    }}
                  >
                    View Clients
                  </button>
                </div>
              ) : null}              
            </div>
          </div>
          <div
            className="card-widget mb-3"
            style={{ backgroundColor: "#2e8f22" }}
          >
            <div className="icon-wid">
              <HiDocumentPlus size={30} />
            </div>
            <div className="text-right">
              <p> Confirmation Due Clients</p>
              <h2>
                {data && data.annualConfirmationDueCount
                  ? data.annualConfirmationDueCount
                  : 0}
              </h2>
              {data && data.annualConfirmationDueCount ? (
                <div>
                  <button
                    className="btn btn-success"
                    style={{ color: "white", height: 34, fontSize: 12 }}
                    onClick={() => {
                      navigate("/clientDueOn",{state: {type:'confirmationDueCount'}});
                    }}
                  >
                    View Clients
                  </button>
                </div>
              ) : null}              
            </div>
          </div>
        </div>
      </div>
    );
};

export default CardCount;
