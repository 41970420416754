import * as AuthenticateAPI from "../actions/ApiCalls";

const getUserDocument = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/templates/viewUserTemplate/${payload}`
  );
};

const saveUserDocument = async (payload) => {
  return await AuthenticateAPI.postResponse(
    `/templates/createUserTemplate`,
    payload
  );
};

const getAllUserTemplates = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/templates/viewAllUserTemplate`,
    payload
  );
};

const getProposalList = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/proposal-format/all-proposal-formats`,
    payload
  );
};

const sendDocument = async (payload) => {
  return await AuthenticateAPI.postResponse(`/proposals/send`, payload);
};

const saveDocument = async (payload) => {
  return await AuthenticateAPI.postResponse(`/proposals/save-draft`, payload);
};

const sendDraftDocument = async (proposalId, payload) => {
  return await AuthenticateAPI.updateCall(
    `/proposals/send-draft/${proposalId}`,
    payload
  );
};

const sendLetterOfEngagement = async (payload) => {
  return await AuthenticateAPI.postResponse(`/engagement-letter/send`, payload);
};

const savePdf = async (payload) => {
  return await AuthenticateAPI.postResponse(`/proposals/save-pdf`, payload);
};

const emailPdf = async (payload) => {
  return await AuthenticateAPI.postResponse(`/proposals/email-pdf`, payload);
};

const getProposal = async (proposalId) => {
  return await AuthenticateAPI.getResponse(
    `/proposals/getProposalData/${proposalId}`,
    proposalId
  );
};

const getLetterOfEngagement = async (proposalId) => {
  return await AuthenticateAPI.getResponse(
    `/engagement-letter/getLetterData/${proposalId}`,
    proposalId
  );
};

const getClientProposals = async (clientProfileId) => {
  return await AuthenticateAPI.getResponse(
    `/client/client-proposals/${clientProfileId}`,
    clientProfileId
  );
};

const getClientEngagementDocuments = async (clientProfileId) => {
  return await AuthenticateAPI.getResponse(
    `/engagement-letter/${clientProfileId}`,
    clientProfileId
  );
};

const requestAmendment = async (payload) => {
  return await AuthenticateAPI.postResponseFormData(
    `/client/reply-proposal`,
    payload
  );
};

const engagementAmendment = async (payload) => {
  return await AuthenticateAPI.postResponseFormData(
    `/client/reply-letterOfEngagement`,
    payload
  );
};

const signDocument = async (payload) => {
  return await AuthenticateAPI.postResponseFormData(
    `/client/reply-proposal`,
    payload
  );
};

const signLetterEngagement = async (payload) => {
  return await AuthenticateAPI.postResponseFormData(
    `/client/reply-letterOfEngagement`,
    payload
  );
};

const getServices = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/service/services-category`,
    payload
  );
};

const allFirmServices = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/service/firm-services-all`,
    payload
  );
};

const getFirmTemplates = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/proposal-format/firm-proposal-formats`,
    payload
  );
};

const DocumentsServices = {
  getUserDocument,
  saveUserDocument,
  getAllUserTemplates,
  getProposalList,
  sendDocument,
  saveDocument,
  sendDraftDocument,
  sendLetterOfEngagement,
  engagementAmendment,
  signLetterEngagement,
  getProposal,
  getLetterOfEngagement,
  requestAmendment,
  signDocument,
  getClientProposals,
  getServices,
  allFirmServices,
  savePdf,
  emailPdf,
  getFirmTemplates,
  getClientEngagementDocuments,
};

export default DocumentsServices;
