import * as AuthenticateAPI from "../actions/ApiCalls";

const getStripeKey = async (payload) => {
  return await AuthenticateAPI.getResponse(`/firm/clients/stripeKey`, payload);
};

const SettingsService = {
  getStripeKey,
};

export default SettingsService;
