import React, { useContext, useEffect, useState } from "react";
import Example from "./chartbar";
import moment from "moment";
import CardCount from "../widgets/CardCount";
import DashboardService from "../../services/Dashboard";
import { toast } from "react-toastify";
import BarChart from "react-bar-chart";
import { FallingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
import notFoundImage from "../../assets/image/no-results.png";
import subscribe from "../../assets/image/subscribe.png";
import { SubscriptionContext } from "src/context";
import { storeObjectData } from "src/services/Storage";

export const Dashboard = () => {
  const [dashboardCounts, setDashBoardCounts] = useState({
    clients: 0,
    proposals: null,
    subscriptionData: null,
    annualAccountDueCount: 0,
  });
  const [barData, setBarData] = useState([]);
  const [pageLoader, setPageLoader] = useState(false);

  const [subscriptionData, setSubscriptionData] =
    useContext(SubscriptionContext);

  const navigate = useNavigate();

  const margin = { top: 20, right: 20, bottom: 30, left: 40 };

  useEffect(() => {
    getFirmDashBoardCount();
    getFirmDashboardProposals();
  }, []);

  const getFirmDashBoardCount = async () => {
    try {
      const response = await DashboardService.getFirmDashBoardCount();
      if (response.status === 200) {
        const counts = { ...response.data };
        let dashboardData = { ...dashboardCounts };
        dashboardData.clients = counts.clientCount;
        dashboardData.proposals = counts.proposalCountData;
        const present = moment(new Date());
        const createdDate = moment(new Date(counts.subscription.created_at));
        const daysDiff = present.diff(createdDate, "days");
        const remainingDays = counts.subscription.dayCredits - daysDiff;
        counts.subscription.remaining_days =
          remainingDays > 0 ? remainingDays : 0;
        dashboardData.subscriptionData = counts.subscription;
        dashboardData.annualAccountDueCount = counts.annualAccountDueCount;
        dashboardData.annualVatDueCount = counts.annualVatDueCount;
        dashboardData.annualConfirmationDueCount =
          counts.annualConfirmationDueCount;
        setDashBoardCounts(dashboardData);
        setSubscriptionData(dashboardData.subscriptionData);
        storeObjectData("subscriptionData", dashboardData.subscriptionData);
      } else {
        toast.error("Unable to acquire dashboard data!");
      }
    } catch (error) {
      toast.error("Unable to acquire dashboard data!");
    }
  };

  const getFirmDashboardProposals = async () => {
    try {
      setPageLoader(true);
      const response = await DashboardService.getFirmDashboardProposals();
      if (response.status === 200 && response.data.length) {
        const barInfo = response.data
          .filter((item) => item)
          .map((item) => {
            if (item) return { text: item.month, value: item.count };
          });
        setBarData(barInfo);
        setPageLoader(false);
      } else {
        setBarData([]);
        setPageLoader(false);
      }
    } catch (error) {
      setBarData([]);
      setPageLoader(false);
    }
  };

  return (
    <div className="container">
      <br />
      <CardCount type={"firm"} data={dashboardCounts} />
      <div>
        {pageLoader && (
          <div
            className="loader-block"
            style={{ padding: 15, textAlign: "center" }}
          >
            <FallingLines
              color="#00699f"
              width="130"
              visible={true}
              ariaLabel="loading..."
              style={{ margin: "auto" }}
            />
            <h1>
              <b>Loading Dashboard ...</b>
            </h1>
          </div>
        )}
        {!pageLoader && (
          <div className="d-flex justify-content-between">
            <div className="bar-widget mb-5 w-75 pr-4 pl-4">
              {!pageLoader && barData.length ? (
                <BarChart
                  ylabel="No. Of Proposals"
                  width={800}
                  height={400}
                  margin={margin}
                  data={barData}
                  style={{ backGroundColor: "black" }}
                  // onBarClick={this.handleBarClick}
                />
              ) : (
                <div
                  className="text-center"
                  style={{ padding: 15, minHeight: "260px", marginTop: 75 }}
                >
                  <img
                    src={notFoundImage}
                    style={{ margin: "auto", width: "100px" }}
                  />
                  <h2
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "black",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      textAlign: "center",
                    }}
                  >
                    No Data Available
                  </h2>
                </div>
              )}
            </div>
            <div className="w-25 pl-5">
              <div>
                <div
                  className="card-widget1"
                  style={{ backgroundColor: "#362578" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="icon-wid">
                      <HiUserCircle size={30} />
                    </div>
                    <p>Active Clients</p>
                    <h2>{dashboardCounts && dashboardCounts.clients}</h2>
                  </div>
                </div>
                <div
                  className="card-widget3"
                  style={{ backgroundColor: "#fff" }}
                >
                  {dashboardCounts && dashboardCounts.subscriptionData ? (
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <strong>
                            <p>Name of Plan : </p>
                          </strong>
                        </td>
                        <td style={{ width: "40%", textAlign: "right" }}>
                          <strong>
                            <p>
                              {dashboardCounts &&
                                dashboardCounts.subscriptionData &&
                                dashboardCounts.subscriptionData.plan.title}
                            </p>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <strong>
                            <p>Number Of days: </p>
                          </strong>
                        </td>
                        <td style={{ width: "40%", textAlign: "right" }}>
                          <strong>
                            <p>
                              {dashboardCounts &&
                              dashboardCounts.subscriptionData
                                ? dashboardCounts.subscriptionData
                                    .remaining_days
                                : 0}
                            </p>
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "60%" }}>
                          <strong>
                            <p>Number of Proposals : </p>
                          </strong>
                        </td>
                        <td style={{ width: "40%", textAlign: "right" }}>
                          <strong>
                            <p>
                              {dashboardCounts &&
                              dashboardCounts.subscriptionData &&
                              dashboardCounts.subscriptionData.proposalCredits
                                ? dashboardCounts.subscriptionData
                                    .proposalCredits
                                : 0}
                            </p>
                          </strong>
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <div
                      className="text-center"
                      style={{ padding: 15, marginTop: 25 }}
                    >
                      <img
                        src={subscribe}
                        style={{
                          margin: "auto",
                          width: "80px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate("/subscription")}
                      />
                      <h2
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "black",
                          paddingTop: "15px",
                          paddingBottom: "15px",
                          textAlign: "center",
                        }}
                      >
                        No Subscription Available
                      </h2>
                    </div>
                  )}

                  <div
                    className="text-center"
                    style={{
                      borderTopWidth: 1,
                      borderColor: "#c2c0c0",
                    }}
                  >
                    {dashboardCounts && dashboardCounts.subscriptionData ? (
                      dashboardCounts.subscriptionData.remaining_days > 0 &&
                      dashboardCounts.subscriptionData.proposalCredits > 0 ? (
                        <button
                          style={{
                            marginTop: 5,
                            color: "white",
                            height: 28,
                            fontSize: 12,
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                          className="btn btn-primary"
                          onClick={() => {
                            if (
                              dashboardCounts &&
                              dashboardCounts.subscriptionData
                            )
                              navigate("/transaction-invoice", {
                                state: {
                                  subscriptionData: JSON.stringify(
                                    dashboardCounts.subscriptionData
                                  ),
                                },
                              });
                          }}
                        >
                          View Transaction Detail
                        </button>
                      ) : (
                        <button
                          style={{
                            marginTop: 5,
                            color: "white",
                            height: 28,
                            fontSize: 12,
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                          className="btn btn-primary"
                          onClick={() => {
                            navigate("/subscription");
                          }}
                        >
                          Subscribe Now
                        </button>
                      )
                    ) : (
                      <button
                        style={{
                          marginTop: 5,
                          color: "white",
                          height: 28,
                          fontSize: 12,
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        className="btn btn-primary"
                        onClick={() => {
                          navigate("/subscription");
                        }}
                      >
                        Subscribe Now
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
