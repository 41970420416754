
export const storeData = async (key, value) => {
  try {
    await localStorage.setItem(key, value);
  } catch (e) {}
};

export const storeObjectData = async (key, obj) => {
  try {
    const jsonValue = JSON.stringify(obj);
    await localStorage.setItem(key, jsonValue);
  } catch (e) {
    console.error(e);
    // saving error
  }
};

export const getData = async (key) => {
  try {
    return await localStorage.getItem(key);
  } catch (e) {
    return e;
    // error reading value
  }
};

export const getObjectData = async (key) => {
  try {

    const jsonValue = await localStorage.getItem(key);

    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {

    console.log(e);
  }
};

export const removeData = async (key) => {
  await localStorage.removeItem(key);
};

export const clear = async () => {
  await localStorage.clear();
};
